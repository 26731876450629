<template>
  <v-hover-content>
    <div class="QRCode">
      <div
        class="QRCode-item"
        v-for="item,index in contentList"
        :key="index"
      >
        <img
          :src="item"
        >
      </div>
    </div>
  </v-hover-content>
</template>

<script>
import vHoverContent from './hoverContent.vue'
export default {
  components: {
    vHoverContent
  },
  props: {
    contentList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="less" scoped>
.QRCode {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  box-sizing: border-box;
  height: 100%;
  &-item{
    height: 129px;
    width: 129px;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
</style>
