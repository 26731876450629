<template>
  <div class="type-list">
    <div
      class="type-item"
      :class="{on: typesId === item.id}"
      v-for="item in typeList"
      :key="item.id"
      @click="handleClick(item.id)"
    >
      {{ item.title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    typesId: {
      type: Number,
      default: 1
    },
    typeList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleClick (id) {
      if (id === this.typesId) return
      this.$emit('update:typesId', id)
    }
  }
}
</script>

<style lang="less" scoped>
.type{
    &-list{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 150px 16px;
        border-bottom: 1px solid #e8e8e8;
        margin-bottom: 40px;
    }
    &-item{
        position: relative;
        cursor: pointer;
        &.on,&:hover{
            color: #4A79F9;
            &::after{
                width: 60px;
            }
        }
        &::after{
            content: "";
            position: absolute;
            bottom: -16px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 2px;
            background: #4A79F9;
            transition: all .3s;
        }
    }
}
</style>
