<template>
  <div class="footer">
    <div class="footer-top">
      <p>
        文锐诚邀您成为我们的合作伙伴，共同推广产品、拓展市场，让我们一起为用户提供更好的软件和服务，共享行业的机遇和成果。
      </p>
      <z-button
        @click.native="$router.push({ name: '联系我们' })"
      >
        成为合作伙伴
      </z-button>
    </div>
    <VSuspensionImg
      position="right"
      img="/static/img/suspendedBlock/a-6.png"
      :img-style="{ transform: 'translate(50%, -100%)', top: 0 }"
    />
  </div>
</template>

<script>
import zButton from '@/components/button'
export default {
  components: {
    zButton
  },
  data () {
    return { }
  }
}
</script>

<style lang="less" scoped>
.footer {
  &-top {
    text-align: center;
    margin-bottom: 40px;
    p {
      display: inline-block;
      width: 100%;
      font-size: 18px;
      color: #000000;
      line-height: 33px;
      margin-bottom: 20px;
    }
  }
  &-contact-information {
    display: flex;
    align-items: center;
    justify-content: space-between;
    &-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: #fff;
      padding: 14px 20px;
      border: 1px solid #e5e5e5;
      border-radius: 6px;
      box-shadow: 0px 0px 4px 0px rgba(107, 107, 107, 0.2);
      .l {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
        img {
          width: 100%;
          max-height: 40px;
        }
      }
      .r {
        font-size: 14px;
        color: #6d7281;
        .tip {
          margin-bottom: 10px;
        }
      }
    }
  }
}
</style>
