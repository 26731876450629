<template>
  <div class="home">
    <v-banner />
    <v-news-center />
    <div class="banner100vw banner100vw-20p30t banner100vw-F7F9FC">
      <v-solution />
    </div>
    <v-product-center />
    <v-customer-case />
    <div class="banner100vw banner100vw-20p30t banner100vw-F7F9FC products-cover-bg">
      <v-products-cover />
    </div>
    <!-- <v-mission /> -->
    <v-constitute />
    <div class="banner100vw banner100vw-20p30t banner100vw-F7F9FC about-bg">
      <v-about />
    </div>
    <v-enterprise />
    <div class="banner100vw banner100vw-20p30t banner100vw-F7F9FC footer-bg500h">
      <v-footer />
    </div>
    <!-- 悬浮窗口 -->
    <v-suspension />
  </div>
</template>

<script>
// NOTE: 首页
// 顶部banner
import vBanner from './components/banner/index.vue'
// 解决方案
import vSolution from './components/solution/index.vue'
// 技术团队=>改为企业活动
import vConstitute from './components/constitute/index.vue'
// 产品中心
import vProductCenter from './components/productCenter/index.vue'
// 客户案例
import vCustomerCase from './components/customerCase/index.vue'
// 产品覆盖
import vProductsCover from './components/productsCover/index.vue'
// 企业使命
// import vMission from './components/corporateMission/index.vue'
// 关于文锐
import vAbout from './components/aboutUs/index.vue'
// 文锐荣耀
import vEnterprise from './components/enterprise/index.vue'
// 新闻中心
import vNewsCenter from './components/newsCenter/index.vue'
// 首页底部
import vFooter from './components/footer/index.vue'
// 悬浮窗口
import vSuspension from './components/suspension/index.vue'
export default {
  components: {
    vBanner,
    vSolution,
    vConstitute,
    vProductCenter,
    vCustomerCase,
    vProductsCover,
    // vMission,
    vAbout,
    vEnterprise,
    vNewsCenter,
    vFooter,
    vSuspension
  },
  data () {
    return {
    }
  },
  mounted () {}
}
</script>

<style lang="less" scoped>
.home{
  .banner100vw();
  .products-cover-bg{
    &::before{
      background: url(/static/img/customerCase/bg.png) no-repeat center center /
    100% auto,#f2f8ff;
    }
  }
  .about-bg{
    &::before{
      background: url('/static/img/home/aboutUs/a-1.png') no-repeat calc(50% - 230px) top, #f2f8ff;
    }
  }
  .footer-bg500h{
    &::before{
      height: 500px;
    }
  }
}
</style>
