<template>
  <div class="about-us">
    <v-subtitle
      zh="关于文锐"
      en="About us"
    >
      <template slot="tips">
        <p style="font-size: 20px;">
          甘肃文锐电子交易网络有限公司成立于2007年，
        </p>
        <p style="font-size: 20px;">
          是专业研发和运营电子招投标系统、公共资源电子交易系统和数字农业平台的软件企业、高新技术企业。
        </p>
      </template>
    </v-subtitle>
    <div class="about-us-text">
      <p>
        公司在兰州、成都和宁波设立研发中心，现有员工100多人，研发人员占公司总人数的70%以上。目前公司70%以上的员工均为本科以上学历。公司是“高新技术企业”和“双软认证企业”，拥有50多项软件著作权和6项专利，2019年11月通过了CMMI3级认证，2020年7月通过了ISO27001信息安全管理体系认证和ISO20000IT服务管理体系认证，是“甘肃省AAA级信誉单位”和“甘肃省诚信经营重点推荐单位”。
      </p>
    </div>
    <VSuspensionImg
      position="left"
      img="/static/img/suspendedBlock/a-5.png"
      :img-style="{transform: 'translate(-50%, -50%)'}"
    />
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
export default {
  components: {
    vSubtitle
  },
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.about-us {
  margin-bottom: 70px;
  background: url('/static/img/home/aboutUs/a-1.png') no-repeat left top;
  &-text {
    text-align: center;
    position: relative;
    top: -28px;
    p {
      display: inline-block;
      width: 890px;
      color: #6D7281;
      font-size: 14px;
    }
  }
}
</style>
