<template>
  <div class="news-center">
    <v-subtitle
      zh="新闻中心"
      en="News center"
    >
      <template slot="tips">
        <p>
          发布公司的新闻和动态，分享行业资讯、市场趋势、技术前沿等方面的内容，帮助您更全面地了解数字农业、公共资源交易和企业招标采购信息化发展趋势
        </p>
      </template>
    </v-subtitle>
    <v-news-nav
      :list="navList"
      @change="navChange"
      :active-index.sync="navListActiveIndex"
    />
    <div
      class="news-list"
      v-show="newsList.length > 0"
    >
      <v-news-main
        v-loading="loading"
        :data="newsListMain"
      />
      <v-news-other
        v-loading="loading"
        :data="newsListOther"
      />
    </div>
    <div
      class="empty"
      v-show="newsList.length === 0"
    >
      暂无数据
    </div>
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
import vNewsNav from './newsNav.vue'
import vNewsMain from './newsMain.vue'
import vNewsOther from './newsOther.vue'
export default {
  components: {
    vSubtitle,
    vNewsNav,
    vNewsMain,
    vNewsOther
  },
  computed: {
    newsListMain () {
      return this.newsList[0]
    },
    newsListOther () {
      return this.newsList.slice(1, 4)
    }
  },
  data () {
    return {
      navList: [],
      navListActiveIndex: 0,
      newsList: [],
      loading: false
    }
  },
  methods: {
    navChange (id) {
      this.getNewsList(id)
    },
    // 新闻分类获取
    getNewsTypes () {
      const params = {
        parentId: this.$siteTypes.siteTypes['新闻']
      }
      this.loading = true
      this.$api
        .getSitetypes(params)
        .then(({ data: res }) => {
          this.loading = false
          if (res.code !== 0) {
            return console.error(res)
          }
          const data = {
            id: '',
            title: '全部'
          }
          this.navList = [data, ...res.data]
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    },
    // 新闻列表
    getNewsList (id) {
      const params = {
        page: 1,
        limit: 4,
        parentId: id
      }
      this.$api
        .getNewsList(params)
        .then(({ data: res }) => {
          if (res.code !== 0) {
            return console.error(res)
          }
          this.newsList = res.data.list
        })
        .catch((err) => {
          console.error(err)
        })
    }
  },
  mounted () {
    this.getNewsTypes()
    this.getNewsList()
  }
}
</script>

<style lang="less" scoped>
.news {
  &-center {
    min-height: 680px;
    margin-bottom: 94px;
    .empty{
      text-align: center;
      font-size: 14px;
      color: #333;
      opacity: 0.5;
    }
  }
  &-list {
    display: flex;
    justify-content: space-between;
  }
}
</style>
