<template>
  <div class="hover">
    <div class="hover-content">
      <!-- 插槽 -->
      <slot />
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.hover {
  position: absolute;
  height: 100%;
  width: 100%;
  &-content {
    overflow: hidden;
    position: relative;
    height: 0;
    width: 0;
    top: 50%;
    padding: 10px 40px 10px 10px;
    box-sizing: border-box;
    background: url(/static/img/home/suspension/hover-bg.png) no-repeat center;
    transform: translate(-50%,-50%);
    opacity: 0;
  }
  &:hover{
    .hover-content{
      min-height: 130px;
      min-width: 430px;
      animation: 0.5s forwards rightLeft;
    }
  }
}
@keyframes rightLeft {
    0% {
        transform: translate(-50%,-50%);
        opacity: 0;
        display: none;
    }
    10% {
        transform: translate(-100%,-50%);
        opacity: 0.9;
        display: block;
    }
    100%{
        transform: translate(-100%,-50%);
        opacity: 1;
        display: block;
    }
}
</style>
