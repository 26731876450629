<template>
  <div>
    <div class="ability-list">
      <div
        class="ability-item"
        v-for="item in viewList"
        :key="item.id"
        @click="toAbilityDetail(item.url)"
      >
        <div class="ability-item-img">
          <img
            :src="item.img"
          >
        </div>
        <div class="ability-item-info">
          <div class="title">
            {{ item.title }}
          </div>
          <div class="desc">
            {{ item.desc }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="ability-all"
      v-if="list.length > 6"
    >
      <div @click="viewAll = !viewAll">
        <i :class="iconStatus[viewAll]" />
        <span>展开更多</span>
      </div>
    </div>
  </div>
</template>

<script>
import idByImg from '../data/abilityImg.js'
export default {
  props: {
    abilityList: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    // 过滤掉没有图片的
    list () {
      let list = this.abilityList.filter(i => i.img)
      list = list.map(i => {
        return {
          ...i,
          img: idByImg[i.img]
        }
      })
      return list
    },
    // 列表前6个
    top6List () {
      if (!this.list.length) return []
      if (this.list.length <= 6) return this.list
      return this.list.slice(0, 6)
    },
    viewList () {
      if (this.viewAll) return this.list
      return this.top6List
    }
  },
  data () {
    return {
      viewAll: false,
      iconStatus: {
        true: 'el-icon-arrow-up',
        false: 'el-icon-arrow-down'
      }
    }
  },
  methods: {
    toAbilityDetail (url) {
      if (!url) return
      window.open(url)
    }
  }
}
</script>

<style lang="less" scoped>
.ability{
    &-list{
        position: relative;
        display: flex;
        flex-wrap: wrap;
        padding-left: 10px;
        padding-top: 20px;
        margin-bottom: 20px;
    }
    &-item{
      display: flex;
      width: calc((100% / 3) - 40px);
      margin-left: 40px;
      margin-bottom: 20px;
      cursor: pointer;
      &:hover{
        .title{
          color: #4A79F9;
        }
      }
      // 第1,3,7...个元素的左边距为0
      &:nth-child(3n+1){
        margin-left: 0;
      }
      &-img{
        height: 30px;
        width: 30px;
        margin-right: 10px;
        img{
          height: 100%;
          width: 100%;
        }
      }
      &-info{
        flex: 1;
        .title{
          font-size: 13px;
          font-weight: bold;
          color: #333333;
          margin-bottom: 10px;
          transition: .1s;
        }
        .desc{
          font-size: 13px;
          color: #000;
          line-height: 1.4;
          // 最多显示三行
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }
      }
    }
    &-all{
      display: flex;
      align-items: center;
      justify-content: center;
      div{
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: .1s;
        &:hover{
          color: #4A79F9;
        }
        span{
          margin-left: 6px;
        }
      }
    }
}
</style>
