<template>
  <div class="sys-list">
    <div
      class="sys-item"
      :class="{ on: sysId === item.id }"
      v-for="item in sysList"
      :key="item.id"
      @mouseenter="handleClick(item.id)"
      @click="toSys(item.aUrl)"
      :title="item.title"
    >
      <img
        v-if="item.imgUrl"
        :src="item.imgUrl"
        :style="item.imgStyle"
      >
      <span v-else>{{ item.title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sysId: {
      type: Number,
      default: -1
    },
    sysList: {
      type: Array,
      default: () => []
    }
  },
  methods: {
    handleClick (id) {
      if (id === this.sysId) return
      this.$emit('update:sysId', id)
    },
    toSys (url) {
      if (!url) return
      window.open(url)
    }
  }
}
</script>

<style lang="less" scoped>
.sys {
  &-list {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-item {
    width: calc((100% / 5) - 10px);
    margin-left: 10px;
    height: 60px;
    background: #fff;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 6px;
    box-sizing: border-box;
    border: 1px solid #fff;
    box-shadow: 0px 0px 0px rgba(74, 121, 249, 0.02),
      0px 0px 0px rgba(74, 121, 249, 0.028),
      0px 0px 0px rgba(74, 121, 249, 0.035),
      0px 0px 0px rgba(74, 121, 249, 0.042),
      0px 0px 0px rgba(74, 121, 249, 0.05), 0px 0px 0px rgba(74, 121, 249, 0.07);
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    // 第1,6,11...个元素的左边距为0
    &:nth-child(5n + 1) {
      margin-left: 0;
    }
    &.on,&:hover {
      border-color: #4a79f9;
      box-shadow: 0px 0.6px 0.5px rgba(74, 121, 249, 0.02),
        0px 1.3px 1.3px rgba(74, 121, 249, 0.028),
        0px 2.5px 2.4px rgba(74, 121, 249, 0.035),
        0px 4.5px 4.2px rgba(74, 121, 249, 0.042),
        0px 8.4px 7.9px rgba(74, 121, 249, 0.05),
        0px 20px 19px rgba(74, 121, 249, 0.07);
    }
    img {
      width: 100%;
    }
  }
}
</style>
