<template>
  <div
    class="news-main"
    @click="toDetails()"
  >
    <div class="news-main-img">
      <el-image
        :src="data.imgUrl"
        fit="cover"
      />
    </div>
    <div class="news-main-title">
      <div
        class="news-main-title-text"
        :title="data.title"
      >
        {{ data.title }}
      </div>
      <div class="news-main-title-date">
        {{ data.pubDate }}
      </div>
    </div>
    <div class="news-main-content">
      <div class="news-main-content-text">
        {{ content120 }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        id: '',
        imgUrl: '',
        title: '',
        date: '',
        content: ''
      })
    }
  },
  computed: {
    content120 () {
      // 去除content中的html标签 并限制长度
      const reg = /<.*?(?:>|\/>)|[&nbsp;]/gi
      const data = this.data.content.replace(reg, '')
      if (data.length > 120) {
        return data.substring(0, 120) + '...'
      } else {
        return data
      }
    }
  },
  methods: {
    toDetails () {
      if (this.data.type === 0) {
        this.$router.push({ path: '/newsCenter/newsDetail', query: { id: this.data.id } })
      } else {
        window.open(this.data.jumpUrl, '_blank')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.news-main {
  width: 49%;
  height: 426px;
  background: #ffffff;
  box-shadow: @gBoxShadow;
  cursor: pointer;
  transition: @gTransition01s;
  &:hover {
    box-shadow: @gBoxShadowHover;
    .news-main-title-text{
      color: @mainColor;
    }
  }
  &-img {
    width: 100%;
    height: 224px;
    background: @imgBgColor;
    >.el-image{
      width: 100%;
      height: 100%;
    }
  }
  &-title {
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    font-size: 20px;
    box-sizing: border-box;
    &-text {
      font-size: 16px;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 20px;
      transition: @gTransition01s;
    }
    &-date {
      font-size: 16px;
      color: @mainColor;
      white-space: nowrap;
    }
  }
  &-content {
    padding: 0 10px;
    &-text {
      font-size: 14px;
      line-height: 24px;
      font-weight: 300;
      color: #6d7281;
    }
  }
}
</style>
