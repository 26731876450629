<template>
  <div class="solution">
    <VSuspensionImg
      position="left"
      img="/static/img/suspendedBlock/a-1.png"
      :img-style="{transform: 'translate(-50%,-127%)'}"
    />
    <!-- banner下解决方案组件 -->
    <!-- <v-top /> -->
    <v-subtitle
      zh="解决方案"
      en="The solution"
    >
      <template slot="tips">
        <p>
          提供公共资源交易、企业招标采购和数字农业全套信息化解决方案，
        </p>
        <p>
          深入应用区块链、大数据和人工智能技术帮助用户创新交易服务模式、提高交易效率效益、丰富交易监管手段。
        </p>
      </template>
    </v-subtitle>
    <v-swiper />
    <VSuspensionImg
      position="right"
      img="/static/img/suspendedBlock/a-2.png"
      :img-style="{transform: 'translate(50%, -50%)'}"
    />
  </div>
</template>

<script>
// import vTop from './top.vue'
import vSubtitle from '@/components/subtitle/index.vue'
import vSwiper from './swiper.vue'
export default {
  components: {
    // vTop,
    vSubtitle,
    vSwiper
  },
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.solution {
  position: relative;
  margin-bottom: 70px;
}
</style>
