<template>
  <div class="news-other">
    <div class="news-other-list">
      <div
        class="news-other-list-item"
        v-for="(item, index) in data58"
        :key="index"
        @click="toDetails(item)"
      >
        <div class="news-other-title">
          <div class="news-other-title-text">
            {{ item.title }}
          </div>
          <div class="news-other-title-date">
            {{ item.pubDate }}
          </div>
        </div>
        <div class="news-other-content">
          <div class="news-other-content-text">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    data58 () {
      // 去除content中的html标签 并限制长度
      const strNum = 54
      const reg = /<.*?(?:>|\/>)|[&nbsp;]/gi
      return this.data.map((item) => {
        let content = item.content ? item.content.replace(reg, '') : ''
        if (content.length > strNum) {
          content = content.substring(0, strNum) + '...'
        }
        return {
          ...item,
          content
        }
      })
    }
  },
  methods: {
    toDetails (item) {
      if (item.type === 0) {
        this.$router.push({
          path: '/newsCenter/newsDetail',
          query: { id: item.id }
        })
      } else {
        window.open(item.jumpUrl, '_blank')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.news-other {
  width: 49%;
  &-list {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    &-item {
      width: 100%;
      height: 134px;
      padding: 25px 30px;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: @gBoxShadow;
      cursor: pointer;
      transition: @gTransition01s;
      &:hover {
        box-shadow: @gBoxShadowHover;
        .news-other-title-text{
          color: @mainColor;
        }
      }
      &:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 20px;
    margin-bottom: 20px;
    box-sizing: border-box;
    &-text {
      font-size: 16px;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 20px;
      transition: @gTransition01s;
    }
    &-date {
      font-size: 16px;
      color: @mainColor;
      white-space: nowrap;
    }
  }
  &-content {
    &-text {
      font-size: 14px;
      line-height: 24px;
      font-weight: 300;
      color: #6d7281;
    }
  }
}
</style>
