<template>
  <div class="constitute-personnel">
    <div class="constitute-personnel-list">
      <div
        class="item"
        v-for="(item, index) in list"
        :key="index"
      >
        <img
          :src="item.img"
          :alt="item.content"
        >
        <div class="item-content">
          <div class="theme">
            {{ item.theme }}
          </div>
          <div class="content">
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
    <div class="more">
      <z-button @click.native="toMore">
        了解更多
      </z-button>
    </div>
  </div>
</template>

<script>
import zButton from '@/components/button'
export default {
  components: {
    zButton
  },
  data () {
    return {
      list: [
        {
          theme: '2020年年会',
          content:
            '加深员工对企业的感情，增进员工之间的沟通和交流，加强员工之间的团队协作意识，创造企业内积极向上的工作氛围，贯彻“让交易变容易”的核心思想。',
          img: '/static/img/constitute/c-1.png'
        },
        {
          theme: '欢乐泰国之旅',
          content:
            '为了鼓励优秀员工的付出，公司在2019年组织了优秀员工去海外体验异国风情，异国团建往往不只是日常工作中的熟悉，还会促进日常生活中的交流，更加增强员工之间的信任感和互相理解。',
          img: '/static/img/constitute/c-2.png'
        },
        {
          theme: '1024程序员节',
          content:
            '在这追梦的时代，总有一些人低头创造未来，他们坚持对知识和技术的追求，用代码改变世界，用实际行动彰显创新，在10.24这个特殊的日子里，公司每年都会举办各种活动，员工可以获得键盘、鼠标等节日相关礼品。',
          img: '/static/img/constitute/c-3.png'
        }
      ]
    }
  },
  methods: {
    toMore () {
      this.$router.push({
        path: '/aboutUs'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.constitute {
  &-personnel {
    &-list {
      display: flex;
      justify-content: center;
      margin-bottom: 30px;
      .item {
        width: 380px;
        background: #fff;
        box-shadow: @gBoxShadowNone;
        transition: @gTransition01s;
        border-radius: 4px;
        overflow: hidden;
        img {
          height: 278px;
          width: 100%;
          background: #eeeeee;
        }
        &-content {
          padding: 16px 18px;
          .theme {
            font-size: 16px;
            color: #000;
            margin-bottom: 10px;
            transition: @gTransition01s;
          }
          .content {
            font-size: 14px;
            color: #666666;
            line-height: 24px;
          }
        }
        & + .item {
          margin-left: 30px;
        }
        &:hover {
          box-shadow: @gBoxShadowHover;
          .theme{
            color: @mainColor;
          }
        }
      }
    }
    .more{
      text-align: center;
    }
  }
}
</style>
