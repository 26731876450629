<template>
  <div class="products-cover">
    <v-subtitle
      zh="产品覆盖"
      en="Products cover"
    >
      <template slot="tips">
        <p>文锐软件产品客户主要分布在甘肃省内，近年开始主动拓展陕西、浙江和四川市场，已经获得外省用户的认可，现诚招各地合作伙伴。</p>
        <p>成为文锐合作伙伴</p>
      </template>
    </v-subtitle>
    <!-- 可视化地图 -->
    <v-map
      @click="selectArea"
      ref="vMap"
    />
    <!-- 左下角 -->

    <div
      class="products-cover-list-item back"
      @click="getSitetradesystem('')"
      v-show="regionId"
    >
      <div class="icon">
        <i class="el-icon-caret-left" />
      </div>
      <div class="name">
        返回省级
      </div>
    </div>
    <div
      class="products-cover-list"
      ref="productsCoverList"
    >
      <div
        class="products-cover-list-item"
        :class="'bg' + index % 4"
        v-for="(item, index) in list"
        :key="index"
        @click="toDetails(item.systemUrl)"
      >
        <div class="icon">
          <i class="el-icon-caret-right" />
        </div>
        <div class="name">
          {{ item.systemName }}
        </div>
      </div>
    </div>
    <VSuspensionImg
      position="right"
      img="/static/img/suspendedBlock/a-4.png"
      :img-style="{transform: 'translate(50%, 50%)', bottom: 0}"
    />
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
import vMap from '@/components/echartsMap/index.vue'
export default {
  components: {
    vSubtitle,
    vMap
  },
  computed: {
    regionNameById () {
      const nameById = {}
      window.SITE_CONFIG.regionList.forEach((item) => {
        nameById[item.name] = item.id
      })
      return nameById
    }

  },
  data () {
    return {
      list: [],
      regionId: '',
      getListIsOk: true
    }
  },
  methods: {
    toDetails (systemUrl) {
      window.open(systemUrl)
    },
    selectArea (params) {
      this.getSitetradesystem(this.regionNameById[params])
    },
    getSitetradesystem (regionId) {
      this.regionId = regionId || ''
      if (!this.regionId) {
        // 清楚高亮
        this.$refs.vMap.clearHighlight()
      }
      const params = {
        regionId: this.regionId
      }
      if (!this.getListIsOk) return
      this.getListIsOk = false
      this.$http.get('/sitetradesystem/page', { params }).then(({ data: res }) => {
        this.getListIsOk = true
        // 滚动到顶部
        this.$refs.productsCoverList.scrollTop = 0
        if (res.code !== 0) {
          this.list = []
          return
        }
        this.list = res.data.list
      }).catch(() => {
        this.getListIsOk = true
      })
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getSitetradesystem()
    })
  }
}
</script>

<style lang="less" scoped>
/deep/ .subtitle{
  .tips{
    margin-bottom: 0;
  }
}
.products-cover {
  margin-bottom: 100px;
  // background: url(/static/img/customerCase/map.png) no-repeat center center /
  //   100% auto;
  height: 900px;
  position: relative;
  &-list {
    position: absolute;
    bottom: 80px;
    left: 0;
    height: 320px;
    padding-right: 4px;
    overflow: hidden;
    &::-webkit-scrollbar-thumb {
      background: #5a89ff;
      border-radius: 3px;
    }
    &::-webkit-scrollbar-track {
      background: #e5e5e5;
      border-radius: 3px;
    }
    // 鼠标移入显示滚动条
    &:hover {
      // 超出显示滚动条
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
      }
    }
    &-item {
      display: flex;
      align-items: center;
      width: 498px;
      height: 58px;
      border-radius: 4px;
      line-height: 58px;
      border: 3px solid #ffffff;
      cursor: pointer;
      &:hover{
        .icon{
            left: 6px;
        }
      }
      // 返回省级
      &:not(:last-child) {
        margin-bottom: 20px;
      }
      .icon {
        position: relative;
        left: 0;
        font-size: 30px;
        color: #5a89ff;
        transition: cubic-bezier(0.075, 0.82, 0.165, 1) .1s;
      }
      // 返回按钮
      &.back {
        position: absolute;
        bottom: 400px;
        width: 184px;
        &:hover {
          .icon{
            left: -3px;
          }
        }
      }
      .name {
        margin-left: 20px;
        font-size: 26px;
        font-family: YouSheBiaoTiHei-Regular, YouSheBiaoTiHei;
        color: #000000;
        line-height: 0px;
        text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
      }
      &.bg0 {
        background: linear-gradient(180deg, #f3f5f8 0%, #fefefe 100%);
      }
      &.bg1 {
        background: linear-gradient(180deg, #e5f2fd 0%, #fefefe 100%);
      }
      &.bg2 {
        background: linear-gradient(180deg, #fff4e9 0%, #fefefe 100%);
      }
      &.bg3 {
        background: linear-gradient(180deg, #feeeee 0%, #fefefe 100%);
      }
    }
  }
}
</style>
