<template>
  <div class="swiper-box">
    <div class="swiper-container">
      <div
        class="swiper-item"
        ref="swiperItemEl"
        v-for="(item, index) in swiperList"
        :key="index"
      >
        <v-temp :data="item" />
      </div>
    </div>
    <div class="swiper-arrow">
      <div
        class="left"
        @click="prev"
        @mouseover="arrowMouseOver"
        @mouseleave="arrowMouseLeave"
      >
        <i class="el-icon-arrow-left" />
      </div>
      <div
        class="right"
        @click="next"
        @mouseover="arrowMouseOver"
        @mouseleave="arrowMouseLeave"
      >
        <i class="el-icon-arrow-right" />
      </div>
    </div>
    <div class="swiper-button">
      <p
        @click="handleClickButton(index)"
        :class="{ on: index === swiperIndex }"
        v-for="(item, index) in swiperList"
        :key="index"
      />
    </div>
  </div>
</template>

<script>
import vTemp from './swiperItemTemp.vue'
export default {
  components: {
    vTemp
  },
  data () {
    return {
      swiperList: [
        {
          id: '1',
          img: '/static/img/solution/b-1.png',
          title: '企业招标采购综合服务解决方案',
          content: '文锐企业招标采购平台提供招投标、非标交易、阳光采购系统、电子商城、废旧物资竞卖等子系统，为企业的非招标项目交易提供多种灵活的采购服务，支持竞争性谈判、竞争性磋商、单一来源采购、内部采购、询价、竞价、直采等多种交易方式，并且可以实现低价分量授标，满足企业的复杂采购需求，保证采购效率和效益最大化。',
          pathName: '企业招标采购综合服务解决方案'
        },
        {
          id: '2',
          img: '/static/img/solution/b-2.png',
          title: '数字农业',
          content: '文锐云上乡村数字农业平台以交易为抓手，帮助农户或农业企业通过手机管资产、管生产、做交易、订服务，提供在线申请奖补资金、办理农业保险、检疫防疫、在线教育等服务，拓宽基层政府部门的涉农服务范畴和渠道。基于平台积累的农产品生产、流通环节的数据，为政府部门提供农业产业方面的实时、详实、可靠的决策依据。',
          pathName: '数字农业'
        },
        {
          id: '3',
          img: '/static/img/solution/b-3.png',
          title: '公共资源交易',
          content: '文锐公共资源交易电子系统具备清晰的交易、服务、监管等子系统功能定位，帮助公共资源交易中心不断优化项目受理、见证、场所、信息、档案、专家抽取、大数据分析等服务。深入应用区块链确保软件系统逻辑公开透明、交易数据不可篡改，以此保证电子化交易的公开、公平、公正。',
          pathName: '公共资源交易'
        }
      ],
      swiperItemEl: null,
      swiperIndex: 0,
      // 设置一个数组，用来存id
      idArr: ['first', 'second', 'last'],
      // 设置一个变量用来当图片的索引
      timer: null,
      timerStep: 5000
    }
  },
  methods: {
    init () {
      this.swiperItemEl = this.$refs.swiperItemEl
      this.swiperIndex = 0
      if (this.swiperList.length > 3) {
        const thePenultimate = this.idArr.length - 2
        this.idArr.splice(thePenultimate, 0, 'right')
        const leftNum = this.swiperList.length - this.idArr.length
        if (leftNum > 0) {
          // 倒数第二插入
          this.idArr.splice(
            thePenultimate,
            0,
            ...new Array(leftNum).fill(0).map((i) => {
              return ['left']
            })
          )
        }
      }

      this.initialize()

      // 设置一个定时器，让图片轮播
      this.timer = setInterval(this.next, this.timerStep)
    },
    // 左右箭头悬浮事件
    arrowMouseOver () {
      clearInterval(this.timer)
      this.timer = null
    },
    arrowMouseLeave () {
      this.timer = setInterval(this.next, this.timerStep)
    },
    // 小圆点点击事件
    handleClickButton (index) {
      // 在用户点击的时候关闭定时器
      clearInterval(this.timer)
      this.timer = null
      // 这里需要判断用户点击的小方块与当前图片的索引之差，如果
      // 大于0，则表明用户需要更换的是后面的图片，反之，表明用户
      // 需要更换之前也就是前面的图片
      if (this.swiperIndex > index) {
        let x = this.swiperIndex - index
        while (x--) {
          this.prev()
        }
      } else if (this.swiperIndex < index) {
        let x = index - this.swiperIndex
        while (x--) {
          this.next()
        }
      }
    },
    // 创建切换图片的函数
    prev () {
      // 切换上一张也就是让数组的最后一个元素变成第一个元素
      this.idArr.push(this.idArr.shift())
      this.initialize()
      if (this.swiperIndex === 0) {
        this.swiperIndex = this.swiperList.length - 1
      } else {
        this.swiperIndex--
      }
    },
    next () {
      // 跟上面反过来
      this.idArr.unshift(this.idArr.pop())
      this.initialize()
      if (this.swiperIndex === this.swiperList.length - 1) {
        this.swiperIndex = 0
      } else {
        this.swiperIndex++
      }
    },
    // 创建一个函数用来初始化图片
    initialize () {
      for (let i = 0; i < this.swiperItemEl.length; i++) {
        this.swiperItemEl[i].id = this.idArr[i]
      }
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang="less" scoped>
.swiper {
  &-box {
    width: 100%;
    height: 520px;
    position: relative;
    margin: 0 auto;
  }
  &-container {
    // -100px为左右两边的箭头的宽度总和
    width: calc(100% - 100px);
    height: 100%;
    margin-bottom: 20px;
  }
  &-item {
    width: 370px;
    position: absolute;
    left: 50%;
    transition: 0.3s;

    background: linear-gradient(180deg, #f3f5f8 0%, #fefefe 100%);
    box-shadow: 0px 3px 6px 1px rgba(14, 0, 140, 0.05);
    border-radius: 4px 4px 4px 4px;
    border: 3px solid #ffffff;
    // 左侧
    &#last {
      transform: translateX(-140%) scale(0.7);
      z-index: 9;
      opacity: 1;
    }
    // 主要
    &#first {
      transform: translateX(-50%);
      z-index: 10;
      opacity: 1;
    }
    // 右侧
    &#second {
      transform: translateX(40%) scale(0.7);
      z-index: 9;
      opacity: 1;
    }
    &#left {
      transform: translateX(-200%) scale(0.7);
      z-index: 1;
      opacity: 0;
    }
    &#right {
      transform: translateX(100%) scale(0.7);
      z-index: 1;
      opacity: 0;
    }
  }
  &-arrow {
    font-size: 32px;
    div {
      position: absolute;
      z-index: 2;
      top: 50%;
      transform: translateY(-50%);

      height: 40px;
      width: 40px;
      cursor: pointer;
      &.left,
      &.right {
        display: flex;
        align-items: center;
        justify-content: center;
        color: #a0a3ac;
        font-size: 16px;
        background: #fff;
        border-radius: 50%;
        box-shadow: 1.4px 2.4px 5.3px rgba(0, 0, 0, 0.024),
          4.7px 8px 17.9px rgba(0, 0, 0, 0.036),
          21px 36px 80px rgba(0, 0, 0, 0.06);
        transition: box-shadow 0.1s;
        &:hover {
          box-shadow: 0px 0px 5.3px rgba(0, 0, 0, 0.004),
            0px 0px 17.9px rgba(0, 0, 0, 0.006),
            0px 0px 80px rgba(0, 0, 0, 0.01);
        }
      }
      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
    }
  }

  &-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    p {
      width: 42px;
      height: 4px;
      background: #333333;
      opacity: 0.18;
      margin: 0 5px;
      cursor: pointer;
      transition: cubic-bezier(0.39, 0.575, 0.565, 1) 0.3s;
      &.on {
        background: #3e70fc;
        opacity: 1;
      }
    }
  }
}
</style>
