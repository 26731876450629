<template>
  <!-- 新闻轮播图 -->
  <div
    class="banner-box banner100vw"
    v-loading="loading"
    v-if="swiperData.length > 1"
  >
    <div class="banner-box-swiper">
      <swiper
        :options="swiperOptions"
        @click-slide="handleClickSlide"
        v-if="!loading"
      >
        <swiper-slide
          v-for="(item, index) in swiperData"
          :key="index"
        >
          <v-banner :data="item" />
        </swiper-slide>
        <!-- 条 -->
        <template #pagination>
          <div class="swiper-pagination" />
        </template>
      </swiper>
    </div>
  </div>
  <v-banner100vw
    :data="swiperData[0]"
    class="banner-one"
    v-else
  >
    <template slot="button">
      <p />
    </template>
  </v-banner100vw>
</template>

<script>
import vBanner from '@/components/banner/index.vue'
import vBanner100vw from '@/components/banner/100vw.vue'
export default {
  components: {
    vBanner,
    vBanner100vw
  },
  props: {
    interval: {
      type: Number,
      default: 5000
    }
  },
  data () {
    return {
      loading: false,
      // #region 轮播图配置
      swiperOptions: {
        autoplay: {
          delay: this.interval
        },
        speed: 1000,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        }
      },
      // #endregion

      // #region 轮播图列表
      // 图片尺寸 986*450
      swiperData: []
    }
  },
  methods: {
    /**
     * 点击轮播图
     */
    handleClickSlide (index, reallyIndex) {
      const item = this.swiperData[reallyIndex]
      console.log('当前点击点击轮播图=>', item)
    },
    /**
     * 获取轮播图
     */
    getSwiperData () {
      this.loading = true
      const params = {
        parentId: this.$siteTypes.bannerTypes['首页']
      }
      this.swiperData = []
      this.$api
        .getBannerInfo(params)
        .then(({ data: res }) => {
          this.loading = false
          if (res.code !== 0) {
            return console.error(res)
          }
          this.swiperData = res.data
        })
        .catch((err) => {
          this.loading = false
          console.error(err)
        })
    }
  },
  mounted () {
    this.getSwiperData()
  }
}
</script>

<style lang="less" scoped>
// 单个轮播图
.banner-one {
  margin-bottom: 50px;
  &::before {
    background: linear-gradient(180deg, #ffffff 0%, #f7f9fc 100%) !important;
  }
}
.banner100vw();
// 多张轮播图
.banner-box {
  width: 100%;
  height: 400px;
  margin-bottom: 90px;
  &-swiper {
    height: 100%;
    width: 100%;
  }
  /deep/ .swiper-container {
    height: 100%;
    .swiper-pagination {
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
    }
    .swiper-pagination-bullet {
      border-radius: 3px;
      height: 5px;
      width: 40px;
      background: #fff;
      opacity: 1;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 20px;
      }
    }
    .swiper-pagination-bullet-active {
      background: @mainColor;
    }
  }
}
</style>
