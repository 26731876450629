<template>
  <div class="enterprise-honor-swiper">
    <v-subtitle
      zh="资质与荣誉"
      en="HONOR OF ENTERPRISE"
    />
    <div class="swiper-prev swiper-btn">
      <i class="el-icon-arrow-left" />
    </div>
    <div class="swiper-next swiper-btn">
      <i class="el-icon-arrow-right" />
    </div>
    <swiper
      :options="swiperOptions"
      @click-slide="handleClickSlide"
    >
      <swiper-slide
        v-for="(item, index) in prizeList"
        :key="index"
      >
        <div class="enterprise-honor">
          <div class="prizes">
            <div
              class="prizes-item"
              v-for="(t, i) in item"
              :key="i"
            >
              <div class="time">
                {{ t.time }}
              </div>
              <div class="text">
                {{ t.content }}
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <!-- 条 -->
      <template #pagination>
        <div class="swiper-pagination" />
      </template>
    </swiper>
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
export default {
  components: {
    vSubtitle
  },
  props: {
    interval: {
      type: Number,
      default: 5000
    }
  },
  data () {
    return {
      swiperIndex: 0,
      // 轮播图配置
      swiperOptions: {
        autoplay: {
          delay: this.interval
        },
        speed: 1000,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          prevEl: '.swiper-prev',
          nextEl: '.swiper-next'
        }
      },
      // 设置一个数组，用来存id
      idArr: ['first', 'second', 'last'],
      // 设置一个变量用来当图片的索引
      timer: null,
      timerStep: 5000,
      prizeList: []
    }
  },
  methods: {
    // 获取荣耀
    getHonourList () {
      this.$http
        .get('/sitewenruihonor/page', { params: { page: 1, limit: 100 } })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            this.prizeList = []
            console.warn('数据获取失败==>', res)
            return
          }
          const list = res.data.list
          for (let i = 0; i < list.length; i += 8) {
            this.prizeList.push(list.slice(i, i + 8))
          }
        })
    },
    handleClickSlide (index, reallyIndex) {
      const item = this.swiperData[reallyIndex]
      console.log('当前点击=>', item)
    },
    // 小圆点点击事件
    handleClickButton (index) {
      // 在用户点击的时候关闭定时器
      clearInterval(this.timer)
      this.timer = null
      // 这里需要判断用户点击的小方块与当前图片的索引之差，如果
      // 大于0，则表明用户需要更换的是后面的图片，反之，表明用户
      // 需要更换之前也就是前面的图片
      if (this.swiperIndex > index) {
        let x = this.swiperIndex - index
        while (x--) {
          this.prev()
        }
      } else if (this.swiperIndex < index) {
        let x = index - this.swiperIndex
        while (x--) {
          this.next()
        }
      }
    },
    // 创建切换图片的函数
    prev () {
      // 切换上一张也就是让数组的最后一个元素变成第一个元素
      this.idArr.push(this.idArr.shift())
      this.initialize()
      if (this.swiperIndex === 0) {
        this.swiperIndex = this.swiperList.length - 1
      } else {
        this.swiperIndex--
      }
    },
    next () {
      // 跟上面反过来
      this.idArr.unshift(this.idArr.pop())
      this.initialize()
      if (this.swiperIndex === this.swiperList.length - 1) {
        this.swiperIndex = 0
      } else {
        this.swiperIndex++
      }
    }
  },
  mounted () {
    this.getHonourList()
  }
}
</script>

<style lang="less" scoped>
.enterprise-honor-swiper {
  width: 100%;
  margin-bottom: 100px;
  position: relative;
  // 轮播图左右按钮
  .swiper-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff;
    height: 110px;
    width: 33px;
    border: 1px solid @imgBgColor;
    font-size: 20px;
    font-weight: bold;
    position: absolute;
    top: 52%;
    z-index: 9;
    transform: translate(0, -50%);
    cursor: pointer;
    transition: background 0.1s;
    &:hover {
      background: #fafbff;
    }
    &.swiper-prev {
      left: 0px;
    }
    &.swiper-next {
      right: 0;
    }
  }
  .enterprise-honor {
    margin-bottom: 80px;
    .prizes {
      display: flex;
      flex-wrap: wrap;
      &-item {
        width: 25%;
        text-align: center;
        margin-bottom: 60px;
        box-sizing: border-box;
        padding: 20px 40px 10px;
        border-right: 1px solid #70707025;
        &:nth-child(4n) {
          border-right: none;
        }
        &:nth-child(n + 5) {
          margin-bottom: 0;
        }
        .time {
          font-size: 26px;
          color: #000;
          margin-bottom: 32px;
        }
        .text {
          font-size: 16px;
          color: #333;
        }
      }
    }
  }
  .swiper-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    p {
      width: 42px;
      height: 4px;
      background: #333333;
      opacity: 0.18;
      margin: 0 5px;
      cursor: pointer;
      transition: cubic-bezier(0.39, 0.575, 0.565, 1) 0.3s;
      &.on {
        background: #3e70fc;
        opacity: 1;
      }
    }
  }
}
/deep/ .swiper-pagination-bullet {
  border-radius: 0;
  width: 50px;
  height: 6px;
}
</style>
