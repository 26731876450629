<template>
  <div class="product-center">
    <v-subtitle
      zh="产品中心"
      en="Product center"
    >
      <template slot="tips">
        <v-type
          :active-index.sync="activeIndex"
          :type-list="types"
        />
        <p
          v-html="nowType.tip"
          class="product-center-title-tip"
        />
      </template>
    </v-subtitle>
    <!-- 主要产品 -->
    <v-product-center-main :data="nowType" />
    <!-- 产品轮播图 -->
    <div class="banner100vw banner100vw-20p30t banner100vw-F7F9FC">
      <v-product-center-swiper :data="nowProductSwiper" />
    </div>
    <VSuspensionImg
      position="left"
      img="/static/img/suspendedBlock/a-3.png"
      :img-style="{ transform: 'translate(-50%, -17%)' }"
    />
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
import vProductCenterMain from './productMain.vue'
import vProductCenterSwiper from './productSwiper.vue'
import vType from './type.vue'

export default {
  components: {
    vSubtitle,
    vProductCenterMain,
    vProductCenterSwiper,
    vType
  },
  computed: {
    nowType () {
      return this.types[this.activeIndex]
    },
    nowProductSwiper () {
      return this.productSwiper[this.nowType.title]
    }
  },
  data () {
    return {
      types: [
        {
          id: '1',
          img: '/static/img/home/productCenter/a-2.jpg',
          title: '公共资源交易',
          tip: '为公共资源交易平台提供整体信息化解决方案，也可提供标准化交易平台硬件设施设计<br/>与建设方案，与阿里云、天威诚信、甘肃广电飞天云、广联达建立了合作伙伴关系，可以为公共资源交易平台提供系统运行平台、CA等服务',
          content:
            '电子服务系统（网站、项目受理服务系统、专家库系统、保证金（保函）管理系统、交易见证系统、电子档案管理系统、大数据平台、移动端服务系统）；电子交易系统（建设工程项目电子招投标系统、政府采购系统、土地交易系统、矿业权交易系统、国有产权交易系统、药品采购系统、限额以下项目自主交易系统）；电子监管系统（大数据监管、在线音视频监控、申投诉处理、信用监管）。'
        },
        {
          id: '2',
          img: '/static/img/home/productCenter/a-3.jpg',
          title: '企业招标采购',
          tip: '为企业提供平台定制化系服务，凭借专业的技术和服务优势为企业数字化转型注入新动能',
          content:
            '电子招投标系统、非标交易系统、阳光采购系统、电子商城、废旧物资竞卖系统、专家库系统、交易见证系统、电子档案系统、大数据平台、移动端服务系统等。'
        },
        {
          id: '3',
          img: '/static/img/home/productCenter/a-4.jpg',
          title: '数字农业',
          tip: '面向现代农业园区、规模化农场提供数字农场解决方案<br/>实现科学化、智能化的监测和管理农业，提升数据化运营能力，帮助政府洞察农业产业发展，实现产业数字化服务和乡村智治',
          content:
            '大宗农产品交易、农资交易、农村产权交易、溯源电商、户情管理、在线检疫、春秋防疫、奖补资金管理、农业保险服务、农产品溯源、扶贫工厂管理、农村普法教育、融资于受托支付服务、大数据等。'
        }
      ],
      activeIndex: 0,
      productMain: {},
      productSwiper: {
        公共资源交易: [
          {
            id: '1',
            img: '/static/img/home/productCenter/a-2.jpg',
            title: '公共资源交易大数据平台',
            url: '/'
          },
          {
            id: '2',
            img: '/static/img/home/productCenter/a-3.jpg',
            title: '公共资源交易专家库系统',
            url: '/'
          },
          {
            id: '3',
            img: '/static/img/home/productCenter/a-4.jpg',
            title: '公共资源交易见证系统',
            url: '/'
          },
          {
            id: '4',
            img: '/static/img/home/productCenter/a-5.jpg',
            title: '阳光采购交易系统',
            url: '/'
          }
        ],
        企业招标采购: [
          {
            id: '1',
            img: '/static/img/home/productCenter/电子采购系统.png',
            title: '电子采购系统',
            url: '/'
          }, {
            id: '2',
            img: '/static/img/home/productCenter/电子招投标系统.png',
            title: '电子招投标系统',
            url: '/'
          }, {
            id: '3',
            img: '/static/img/home/productCenter/非标交易系统.png',
            title: '非标交易系统',
            url: '/'
          }, {
            id: '4',
            img: '/static/img/home/productCenter/废旧物资竞买系统.png',
            title: '废旧物资竞买系统',
            url: '/'
          }, {
            id: '5',
            img: '/static/img/home/productCenter/交易见证系统.png',
            title: '交易见证系统',
            url: '/'
          }, {
            id: '6',
            img: '/static/img/home/productCenter/阳光采购系统.png',
            title: '阳光采购系统',
            url: '/'
          }, {
            id: '7',
            img: '/static/img/home/productCenter/专家库系统.png',
            title: '专家库系统',
            url: '/'
          }
        ],
        数字农业: [
          {
            id: '1',
            img: '/static/img/home/productCenter/大宗农产品交易.jpg',
            title: '大宗农产品交易',
            url: '/'
          }, {
            id: '2',
            img: '/static/img/home/productCenter/户情管理.jpg',
            title: '户情管理',
            url: '/'
          }, {
            id: '3',
            img: '/static/img/home/productCenter/农资交易.jpg',
            title: '农资交易',
            url: '/'
          }, {
            id: '4',
            img: '/static/img/home/productCenter/溯源电商.jpg',
            title: '溯源电商',
            url: '/'
          }]
      }
    }
  },
  methods: {}
}
</script>

<style lang="less" scoped>
.product-center {
  margin-bottom: 90px;
  position: relative;
  .banner100vw();
  &-title-tip{
    min-height: 40px;
  }
}
</style>
