<template>
  <div class="customer-case">
    <v-subtitle
      zh="客户案例"
      en="Customer case"
      :tips-style="'margin-bottom: 30px;'"
    >
      <template slot="tips">
        <p>以软件为核心的智慧化整体解决方案</p>
      </template>
    </v-subtitle>
    <div class="banner100vw banner100vw-20p30t banner100vw-F7F9FC">
      <div class="type-box">
        <v-type
          :types-id.sync="typesId"
          :type-list="types"
        />
        <v-sys-list
          :sys-id.sync="sysId"
          :sys-list="sysList"
        />
      </div>
    </div>
    <v-ability-list
      :ability-list="abilityList"
    />
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
import vType from './components/type.vue'
import vSysList from './components/sysList.vue'
import vAbilityList from './components/abilityList.vue'
import dataAll from './data/data'
dataAll.forEach(item => {
  item.sys.forEach(i => {
    const aUrl = i.ability[0].url
    i.aUrl = aUrl
  })
})

export default {
  components: {
    vSubtitle,
    vType,
    vSysList,
    vAbilityList
  },
  computed: {
    types () {
      return dataAll.map(item => {
        return {
          id: item.id,
          title: item.title
        }
      })
    },
    sysList () {
      const list = dataAll.filter(item => item.id === this.typesId)
      return list.length && list[0].sys ? list[0].sys : []
    },
    abilityList () {
      const list = this.sysList.filter(item => item.id === this.sysId)
      return list.length && list[0].ability ? list[0].ability : []
    }
  },
  watch: {
    // types选中的id 改变时，sysList也要改变.
    typesId () {
      this.sysId = 1
    }
  },
  data () {
    return {
      typesId: 1,
      sysId: 1
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
.customer-case {
  margin-bottom: 50px;
  .banner100vw();
  .type-box {
    padding-bottom: 40px;
    margin-bottom: 40px;
  }

}
</style>
