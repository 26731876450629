<template>
  <div class="constitute">
    <v-subtitle
      zh="企业文化"
      en="Enterprise activity"
    >
      <template slot="tips">
        <p>文锐以“开放、团结、敬业、创新”为企业文化的核心价值观。</p>
        <p>我们是一支充满活力的年轻团队，坚持以人为本，勇于挑战、不断创新。我们对产品和服务充满激情，致力为用户提供最好的体验！</p>
      </template>
    </v-subtitle>
    <v-constitute-personnel />
  </div>
</template>

<script>
import vSubtitle from '@/components/subtitle/index.vue'
import vConstitutePersonnel from './personnel.vue'
export default {
  components: {
    vSubtitle,
    vConstitutePersonnel
  }
}
</script>

<style lang="less" scoped>
.constitute {
  margin-bottom: 68px;
}
</style>
