export default {
  1: '/static/img/abilityImg/1.png',
  2: '/static/img/abilityImg/2.png',
  3: '/static/img/abilityImg/3.png',
  4: '/static/img/abilityImg/4.png',
  5: '/static/img/abilityImg/5.png',
  6: '/static/img/abilityImg/6.png',
  7: '/static/img/abilityImg/7.png',
  8: '/static/img/abilityImg/8.png',
  9: '/static/img/abilityImg/9.png',
  10: '/static/img/abilityImg/10.png',
  11: '/static/img/abilityImg/11.png',
  12: '/static/img/abilityImg/12.png',
  13: '/static/img/abilityImg/13.png',
  14: '/static/img/abilityImg/14.png',
  15: '/static/img/abilityImg/15.png'
}
