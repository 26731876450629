<template>
  <v-hover-content>
    <div class="phone">
      <div
        class="phone-item"
        v-for="item,index in contentList"
        :key="index"
      >
        <p
          class="phone-item-title"
          v-text="item.title"
        />
        <div class="phone-item-value">
          <p
            v-for="(value, telIndex) in item.value"
            :key="telIndex"
            v-text="value"
          />
        </div>
      </div>
    </div>
  </v-hover-content>
</template>

<script>
import vHoverContent from './hoverContent.vue'
export default {
  components: {
    vHoverContent
  },
  props: {
    contentList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  }
}
</script>

<style lang="less" scoped>
.phone {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 10px 30px;
  box-sizing: border-box;
  &-item{
    &-title{
      font-size: 18px;
      color: #000;
      margin-bottom: 16px;
      padding-left: 10px;
      border-left: 2px solid @mainColor;
      line-height: 1;
    }
    &-value{
      color: @mainColor;
      font-size: 20px;
      p:not(:last-child){
        margin-bottom: 8px;
      }
    }
  }
}
</style>
