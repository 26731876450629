<template>
  <div class="temp">
    <div class="temp-img">
      <img :src="data.img">
    </div>
    <div class="temp-main">
      <p class="title">
        {{ data.title }}
      </p>
      <p class="content">
        {{ data.content }}
      </p>
    </div>
    <div class="temp-btn">
      <z-button
        type="circle inset"
        @click.native="toDetails()"
      >
        了解详情
      </z-button>
    </div>
  </div>
</template>

<script>
import zButton from '@/components/button/index.vue'
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        img: '',
        title: '',
        content: '',
        id: '',
        url: '',
        pathName: ''
      })
    }
  },
  components: {
    zButton
  },
  data () {
    return {}
  },
  methods: {
    toDetails () {
      if (this.data.url) return window.open(this.data.url)
      if (this.data.pathName) return this.$router.push({ name: this.data.pathName })
    }
  }
}
</script>

<style lang="less" scoped>
.temp {
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 24px 20px;
  box-sizing: border-box;
  &-img {
    width: 248px;
    height: 188px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    img {
      height: 100%;
    }
  }
  &-main {
    .title {
      font-size: 20px;
      color: #000000;
      margin-bottom: 22px;
      text-align: center;
    }
    .content {
      font-size: 14px;
      color: #6d7281;
      line-height: 24px;
      text-indent: 2em;
    }
  }
}
</style>
