<template>
  <div class="product-swiper">
    <div class="swiper-prev swiper-btn">
      <i class="el-icon-arrow-left" />
    </div>
    <div class="swiper-next swiper-btn">
      <i class="el-icon-arrow-right" />
    </div>
    <div class="swiper-box">
      <swiper
        :options="swiperOptions"
        @click-slide="handleClickSlide"
      >
        <swiper-slide
          v-for="(item, index) in data"
          :key="index"
        >
          <div class="swiper-item">
            <div class="swiper-item-img">
              <img :src="item.img">
            </div>
            <div class="swiper-item-title">
              {{ item.title }}
            </div>
          </div>
        </swiper-slide>
        <!-- 条 -->
        <template #pagination>
          <div class="swiper-pagination" />
        </template>
      </swiper>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    interval: {
      type: Number,
      default: 5000
    }
  },
  data () {
    return {
      swiperOptions: {
        autoplay: {
          delay: this.interval
        },
        speed: 1000,
        slidesPerView: 4,
        loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          prevEl: '.swiper-prev',
          nextEl: '.swiper-next'
        }
      }
    }
  },
  methods: {
    /**
     * 点击轮播图
     */
    handleClickSlide (index, reallyIndex) {
      const item = this.data[reallyIndex]
      console.log('当前点击=>', item)
      window.open(item.url)
    }
  }
}
</script>

<style lang="less" scoped>
.product-swiper {
  height: 240px;
  position: relative;
  // 底部小圆点样式
  /deep/ .swiper-container {
    height: 100%;
    .swiper-pagination {
      bottom: 10px;
      left: 50%;
      transform: translateX(-50%);
      width: auto;
    }
    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      background: #333333 !important;
      border-radius: 0;
      background: #fff;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 10px;
      }
    }
    .swiper-pagination-bullet-active {
      background: @mainColor;
    }
  }
}
.swiper-box {
  height: 100%;
  width: 90%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
}

// 轮播图左右按钮
.swiper-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  height: 110px;
  width: 33px;
  border: 1px solid @imgBgColor;
  font-size: 20px;
  font-weight: bold;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  cursor: pointer;
  transition: background 0.1s;
  &:hover {
    background: #fafbff;
  }
  &.swiper-prev {
    left: 0;
  }
  &.swiper-next {
    right: 0;
  }
}
// 轮播图每一项样式
.swiper-item {
  background: #ffffff;
  width: 230px;
  box-shadow: 0px 3px 6px 1px rgba(6, 74, 118, 0.05);
  border-radius: 4px;
  cursor: pointer;
  &-img {
    height: 162px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    img {
      height: 100%;
    }
  }
  &-title {
    height: 40px;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    color: #333;
    transition: @gTransition01s;
  }
  &:hover{
    .swiper-item-title{
      color: @mainColor;
    }
  }
}
</style>
