export default [
  {
    id: 1,
    title: '公共资源交易领域',
    sys: [
      {
        id: 1,
        title: '甘肃省公共资源交易中心',
        imgUrl: 'https://traceable-platform.oss-cn-hangzhou.aliyuncs.com/test/temp/20230426/51dd638368714c48a73bde07ab5167dd.png',
        ability: [
          {
            id: 1,
            title: '公共资源交易主体共享平台',
            desc: '交易主体自主进行注册登记，进行统一身份认证，认证后的身份信息作用于整个电子交易平台中，交易主体管理系统统一定义交易主体的身份类别，在电子交易平台各个子系统或接入了交易主体互认共享平台的其他交易平台中使用统一的身份信息及系统角色登录和操作系统。\n在交易主体管理系统以交易主体的维度汇总电子交易平台，自动评估交易主体信用信息，并生成各交易主体的信用报告，为评标专家及甲方提供辅助依据。\n',
            url: 'https://gxpt.ggzyjy.gansu.gov.cn',
            img: 9
          },
          {
            id: 2,
            title: '公共资源交易网',
            desc: '实现招投标过程电子化、规范化，适用于任何交易方式，包括公开招标、邀请招标、竞争性谈判、竞争性磋商、询价、单一来源等等。',
            url: 'https://ggzyjy.gansu.gov.cn',
            img: 15
          },
          {
            id: 3,
            title: '一网通办系统',
            desc: '一网通办、统一入口  化繁为简，交易主体无需再奔波于各个子系统之间而无所适从\n全程网办、高效便捷  全网高效，为交易主体提供“一次也不跑、键办不见面”服务\n互联互通、资源共享  资源整合，实现交易信息互联互通，评标专家、场地资源跨区域共享',
            url: 'https://ywtb.ggzyjy.gansu.gov.cn',
            img: 8
          },
          {
            id: 4,
            title: '智能见证系统',
            desc: '独立于电子服务系统和电子交易系统运行，可全量记录交易过程中的所有交易数据及交易主体行为数据，形成交易见证档案，并采用数字签名和时间戳等技术手段保证数据的合法有效性，真正实现交易全过程的可溯可查，并为大数据平台提供可靠的数据源，为实现智慧监管奠定基础，为远程在线监管交易提供了有效手段。',
            url: 'http://47.97.155.205:8090/#/login',
            img: 3
          },
          {
            id: 5,
            title: '电子档案系统',
            desc: '对招投标过程所有资料，并上链存储，保证无法进行修改，后期可以在问题追溯、审计等场景中查看项目资料。',
            url: 'http://118.178.149.27:7001/archives/#/login',
            img: 6
          },
          {
            id: 6,
            title: '大数据平台',
            desc: '大数据平台全面实现了数据的采集、清理、存储、分析、展现和应用。采用了可视化数据展现方式，直观的向各类用户展现统计分析结果；\n系统提供开放的数据接口，开放的对接公共资源交易或企业相关的各类系统，为政府/企业决策提供支撑、为交易监管提供线索、为交易主体提供精准的个性化服务。\n',
            url: 'https://dsj.ggzyjy.gansu.gov.cn',
            img: 11
          },
          {
            id: 7,
            title: '三隔离系统',
            desc: '',
            url: 'http://192.168.9.223:9001/#/login'
          },
          {
            id: 8,
            title: '限额以下项目阳光招标采购平台',
            desc: '',
            url: 'https://ygjy.ggzyjy.gansu.gov.cn:3085/f/index'
          },
          {
            id: 9,
            title: '远程异地评标专家库',
            desc: '专家抽取过程人工参与，无法保证专家信息的保密性；\n对于专家的评标过程是否存在倾向性，无法进行评估。',
            url: '',
            img: 10
          },
          {
            id: 10,
            title: '区块链基础服务平台',
            desc: '',
            url: ''
          },
          {
            id: 11,
            title: '基于区块链的网上开评标系统',
            desc: '在线开标系统实现全程开标电子化，开标各环节均系统留痕，保证公平公正，减少人为因素干预；\n在线评标系统根据招标文件设定的评标方法、评审因素和标准设置评审表格和评审项目；具备按招标文件约定的评标方法，对投标文件进行解析、对比，辅助评分或计算评标价的功能；系统可自动生成评标报告，评委会成员可进行电子签名。\n',
            img: 7
          },
          {
            id: 12,
            title: '交通项目电子招投标系统',
            desc: '',
            url: ''
          },
          {
            id: 13,
            title: '水利项目电子招投标系统',
            desc: '',
            url: ''
          },
          {
            id: 14,
            title: '政府采购电子交易系统',
            desc: '政府采购交易系统具备模板化的招标文件编制工具、投标文件编制工具、在线开标和在线评标功能；引导式和答题式的编标工具可以极大的降低编标难度，有效防止人为失误导致的废标；在线开标系统可以实现不见面开标，为投标人降低交易成本，极大的提高交易效率；系统结合文锐交易大数据产品可智能化分析招标文件倾向性和投标文件雷同性，可为评标专家提供评审依据和数据支撑服务。\n',
            url: ''
          },
          {
            id: 15,
            title: '药品阳光采购平台（下线）',
            desc: '药品阳光采购系统可为医疗机构提供网上自主采购服务，系统提供了自主采购、联合采购、跟单采购、合同续签等交易方式；系统支持竞价和议价两种价格确定方式；系统具备两票制核验功能。\n',
            url: ''
          }
        ]
      },
      {
        id: 2,
        title: '平凉市公共资源交易中心',
        imgUrl: 'https://traceable-platform.oss-cn-hangzhou.aliyuncs.com/test/temp/20230426/f94e236d181647f2be93ea8b283ea26a.png',
        ability: [
          {
            id: 1,
            title: '平凉市公共资源交易网',
            desc: '交易信息全面公开的门户，不仅作为采购方与投标方联系的“纽带”，也是交易平台显示“公平、公正、公开”最直接的方式及载体。\n交易平台运营方可向公众发布关于交易平台各项通知等内容，采购方可向不特定群体公开发布项目信息，投标人也可在网站中咨询相关问题或表达相关质疑及投诉。\n',
            url: 'http://www.plsggzyjy.cn/f',
            img: 13
          },
          {
            id: 2,
            title: '平凉市公共资源交易电子服务系统',
            desc: '实现招投标过程电子化、规范化，适用于任何交易方式，包括公开招标、邀请招标、竞争性谈判、竞争性磋商、询价、单一来源等等',
            url: 'http://47.114.12.78/Accounts/Login?ReturnUrl=%2f',
            img: 15
          },
          {
            id: 3,
            title: '平凉市公共资源交易智慧监管平台',
            url: 'http://114.215.217.207:18336/witness/a/login'
          },
          {
            id: 4,
            title: '甘肃省阳光招标采购平台（平凉市）',
            desc: '',
            url: 'http://www.plsggzyjy.cn/f/news/sunIndex'
          },
          {
            id: 5,
            title: '公共资源交易大数据平台',
            desc: '大数据平台全面实现了数据的采集、清理、存储、分析、展现和应用。采用了可视化数据展现方式，直观的向各类用户展现统计分析结果；\n系统提供开放的数据接口，开放的对接公共资源交易或企业相关的各类系统，为政府/企业决策提供支撑、为交易监管提供线索、为交易主体提供精准的个性化服务。\n',
            url: 'http://118.178.149.27/#/login',
            img: 11
          },
          {
            id: 6,
            title: '平凉市土地矿权交易系统',
            desc: '土地和矿业权交易系统可以实现标的物的网上挂牌和拍卖，具备满足标的物交易所需的保证金管理功能和延时竞价功能，可根据实际需求设置竞价策略。\n',
            url: 'http://47.114.12.186:1010/a/login',
            img: 5
          },
          {
            id: 7,
            title: '平凉市资产产权类、环境类、自然资源类项目交易系统',
            desc: '国有产权交易系统可以实现标的物的网上挂牌和拍卖，具备满足标的物交易所需的保证金管理功能和延时竞价功能，可根据实际需求设置竞价策略。\n',
            url: 'http://47.114.12.186:2010/a/login',
            img: 5
          },
          {
            id: 8,
            title: '平凉市公共资源交易电子档案系统',
            desc: '对招投标过程所有资料，并上链存储，保证无法进行修改，后期可以在问题追溯、审计等场景中查看项目资料。',
            url: 'http://47.98.100.213:27001/#/login',
            img: 6
          },
          {
            id: 9,
            title: '网上开评标系统',
            desc: '在线开标系统实现全程开标电子化，开标各环节均系统留痕，保证公平公正，减少人为因素干预；\n在线评标系统根据招标文件设定的评标方法、评审因素和标准设置评审表格和评审项目；具备按招标文件约定的评标方法，对投标文件进行解析、对比，辅助评分或计算评标价的功能；系统可自动生成评标报告，评委会成员可进行电子签名。\n',
            url: 'https://wskpb.ggzyjy.gansu.gov.cn/OpenTender/login',
            img: 7
          },
          {
            id: 10,
            title: '一网通办系统',
            desc: '一网通办、统一入口  化繁为简，交易主体无需再奔波于各个子系统之间而无所适从\n全程网办、高效便捷  全网高效，为交易主体提供“一次也不跑、键办不见面”服务\n互联互通、资源共享  资源整合，实现交易信息互联互通，评标专家、场地资源跨区域共享',
            url: 'https://ywtb.ggzyjy.gansu.gov.cn/#/login',
            img: 8
          },
          {
            id: 11,
            title: '专家库系统',
            desc: '专家抽取过程人工参与，无法保证专家信息的保密性；\n对于专家的评标过程是否存在倾向性，无法进行评估。',
            url: 'http://localhost:9091/a/login',
            img: 10
          },
          {
            id: 12,
            title: '平凉市公共资源交易中心身份认证与电子签名系统',
            desc: '',
            url: 'https://zhfw.plsggzyjy.cn/#/login'
          }
        ]
      },
      {
        id: 3,
        title: '庆阳市公共资源交易中心',
        imgUrl: 'https://traceable-platform.oss-cn-hangzhou.aliyuncs.com/test/temp/20230426/38cc8903dce74b63b413c49b205f2353.png',
        ability: [
          {
            id: 1,
            title: '庆阳市公共资源交易中心网站',
            desc: '交易信息全面公开的门户，不仅作为采购方与投标方联系的“纽带”，也是交易平台显示“公平、公正、公开”最直接的方式及载体。\n交易平台运营方可向公众发布关于交易平台各项通知等内容，采购方可向不特定群体公开发布项目信息，投标人也可在网站中咨询相关问题或表达相关质疑及投诉。\n',
            url: 'http://www.qysggzyjy.cn/f',
            img: 13
          },
          {
            id: 2,
            title: '庆阳市智慧交易平台',
            url: 'http://www.qysggzyjy.cn:9092/wisdom/#/wisdom/service-hall/home-page?pop=true'
          },
          {
            id: 3,
            title: '主体共享平台',
            desc: '交易主体自主进行注册登记，进行统一身份认证，认证后的身份信息作用于整个电子交易平台中，交易主体管理系统统一定义交易主体的身份类别，在电子交易平台各个子系统或接入了交易主体互认共享平台的其他交易平台中使用统一的身份信息及系统角色登录和操作系统。\n在交易主体管理系统以交易主体的维度汇总电子交易平台，自动评估交易主体信用信息，并生成各交易主体的信用报告，为评标专家及甲方提供辅助依据。\n',
            url: 'http://ggzyjy.gansu.gov.cn/ggzyjy/c112581/fwxx_yhzc.shtml',
            img: 9
          },
          {
            id: 4,
            title: '旧庆阳市公共资源交易电子服务系统',
            desc: '实现招投标过程电子化、规范化，适用于任何交易方式，包括公开招标、邀请招标、竞争性谈判、竞争性磋商、询价、单一来源等等',
            url: 'http://www.qysggzyjy.cn:9091/Accounts/Login?ReturnUrl=%2f',
            img: 15
          },
          {
            id: 5,
            title: '西峰区公共资源交易电子服务系统',
            desc: '实现招投标过程电子化、规范化，适用于任何交易方式，包括公开招标、邀请招标、竞争性谈判、竞争性磋商、询价、单一来源等等',
            url: 'http://125.74.125.193:8082/Accounts/Login?ReturnUrl=%2f',
            img: 15
          },
          {
            id: 6,
            title: '庆城县公共资源交易电子服务系统',
            desc: '实现招投标过程电子化、规范化，适用于任何交易方式，包括公开招标、邀请招标、竞争性谈判、竞争性磋商、询价、单一来源等等',
            url: 'http://47.97.207.119:8084/Accounts/Login?ReturnUrl=%2f',
            img: 15
          },
          {
            id: 7,
            title: '宁县公共资源交易电子服务系统',
            desc: '实现招投标过程电子化、规范化，适用于任何交易方式，包括公开招标、邀请招标、竞争性谈判、竞争性磋商、询价、单一来源等等',
            url: 'http://118.180.166.245:8081/Accounts/Login?ReturnUrl=%2f',
            img: 15
          },
          {
            id: 8,
            title: '镇原县公共资源交易电子服务系统',
            desc: '实现招投标过程电子化、规范化，适用于任何交易方式，包括公开招标、邀请招标、竞争性谈判、竞争性磋商、询价、单一来源等等',
            url: 'http://124.152.185.147:8082/',
            img: 15
          },
          {
            id: 9,
            title: '正宁县公共资源交易电子服务系统',
            desc: '实现招投标过程电子化、规范化，适用于任何交易方式，包括公开招标、邀请招标、竞争性谈判、竞争性磋商、询价、单一来源等等',
            url: 'http://47.97.207.119:8083/Accounts/Login?ReturnUrl=%2f',
            img: 15
          },
          {
            id: 10,
            title: '环县公共资源交易电子服务系统',
            desc: '实现招投标过程电子化、规范化，适用于任何交易方式，包括公开招标、邀请招标、竞争性谈判、竞争性磋商、询价、单一来源等等',
            url: 'http://125.75.246.10:8081/Accounts/Login?ReturnUrl=%2f',
            img: 15
          },
          {
            id: 11,
            title: '华池县公共资源交易电子服务系统',
            desc: '实现招投标过程电子化、规范化，适用于任何交易方式，包括公开招标、邀请招标、竞争性谈判、竞争性磋商、询价、单一来源等等',
            url: 'http://117.156.196.12:8082/Accounts/Login?ReturnUrl=%2f',
            img: 15
          },
          {
            id: 12,
            title: '合水县公共资源交易电子服务系统',
            desc: '实现招投标过程电子化、规范化，适用于任何交易方式，包括公开招标、邀请招标、竞争性谈判、竞争性磋商、询价、单一来源等等',
            url: 'http://47.97.207.119:8099/Accounts/Login?ReturnUrl=%2f',
            img: 15
          },
          {
            id: 13,
            title: '市县一体化服务系统',
            desc: '',
            url: 'http://www.qysggzyjy.cn:7071/Accounts/Login?ReturnUrl=%2f',
            img: 9
          },
          {
            id: 14,
            title: '庆阳市公共资源土地和矿权交易系统',
            desc: '土地和矿业权交易系统可以实现标的物的网上挂牌和拍卖，具备满足标的物交易所需的保证金管理功能和延时竞价功能，可根据实际需求设置竞价策略。\n',
            url: 'http://www.qysggzyjy.cn:9094/a/login',
            img: 5
          },
          {
            id: 15,
            title: '庆阳市国有产权交易系统',
            desc: '国有产权交易系统可以实现标的物的网上挂牌和拍卖，具备满足标的物交易所需的保证金管理功能和延时竞价功能，可根据实际需求设置竞价策略。\n',
            url: 'http://www.qysggzyjy.cn:9095/a/login',
            img: 14
          },
          {
            id: 16,
            title: '庆阳市公共资源交易见证系统',
            desc: '独立于电子服务系统和电子交易系统运行，可全量记录交易过程中的所有交易数据及交易主体行为数据，形成交易见证档案，并采用数字签名和时间戳等技术手段保证数据的合法有效性，真正实现交易全过程的可溯可查，并为大数据平台提供可靠的数据源，为实现智慧监管奠定基础，为远程在线监管交易提供了有效手段。',
            url: 'http://125.74.125.183:8086/archives/a/login',
            img: 3
          },
          {
            id: 17,
            title: '公共资源交易大数据平台',
            desc: '大数据平台全面实现了数据的采集、清理、存储、分析、展现和应用。采用了可视化数据展现方式，直观的向各类用户展现统计分析结果；\n系统提供开放的数据接口，开放的对接公共资源交易或企业相关的各类系统，为政府/企业决策提供支撑、为交易监管提供线索、为交易主体提供精准的个性化服务。\n',
            url: 'http://118.178.149.27/#/login',
            img: 11
          },
          {
            id: 18,
            title: '限额以下工程项目阳光交易系统（庆阳市）',
            desc: '',
            url: 'http://www.qysggzyjy.cn:9096/a/login'
          },
          {
            id: 19,
            title: '政府采购限额以下项目阳光交易系统（庆阳市）',
            desc: '',
            url: 'http://www.qysggzyjy.cn:9097/a/login'
          },
          {
            id: 20,
            title: '庆阳市公共资源交易电子档案系统',
            desc: '对招投标过程所有资料，并上链存储，保证无法进行修改，后期可以在问题追溯、审计等场景中查看项目资料。',
            url: 'http://www.qysggzyjy.cn:9093/archives/a/login',
            img: 6
          },
          {
            id: 21,
            title: '甘肃省保市场主体公共服务平台',
            desc: '交易主体自主进行注册登记，进行统一身份认证，认证后的身份信息作用于整个电子交易平台中，交易主体管理系统统一定义交易主体的身份类别，在电子交易平台各个子系统或接入了交易主体互认共享平台的其他交易平台中使用统一的身份信息及系统角色登录和操作系统。\n在交易主体管理系统以交易主体的维度汇总电子交易平台，自动评估交易主体信用信息，并生成各交易主体的信用报告，为评标专家及甲方提供辅助依据。\n',
            url: 'http://www.gssczt.cn/w/index?province=0',
            img: 9
          },
          {
            id: 22,
            title: '庆阳市公共资源交易招标代理中介服务超市',
            desc: '',
            url: 'http://125.74.125.172:8085/frontPage'
          },
          {
            id: 23,
            title: '智慧监管系统',
            desc: '',
            url: 'http://125.74.125.183:8087/#/login'
          }
        ]
      },
      {
        id: 4,
        title: '嘉峪关市公共资源交易中心',
        imgUrl: 'https://traceable-platform.oss-cn-hangzhou.aliyuncs.com/test/temp/20230428/e9643841d07f4aa79735eae7d094aa5a.png',
        ability: [
          {
            id: 1,
            title: '嘉峪关市公共资源交易中心官网',
            desc: '交易信息全面公开的门户，不仅作为采购方与投标方联系的“纽带”，也是交易平台显示“公平、公正、公开”最直接的方式及载体。\n交易平台运营方可向公众发布关于交易平台各项通知等内容，采购方可向不特定群体公开发布项目信息，投标人也可在网站中咨询相关问题或表达相关质疑及投诉。\n',
            url: 'http://www.jygzyjy.gov.cn/f',
            img: 13
          },
          {
            id: 2,
            title: '甘肃省公共资源交易主体共享平台',
            desc: '交易主体自主进行注册登记，进行统一身份认证，认证后的身份信息作用于整个电子交易平台中，交易主体管理系统统一定义交易主体的身份类别，在电子交易平台各个子系统或接入了交易主体互认共享平台的其他交易平台中使用统一的身份信息及系统角色登录和操作系统。\n在交易主体管理系统以交易主体的维度汇总电子交易平台，自动评估交易主体信用信息，并生成各交易主体的信用报告，为评标专家及甲方提供辅助依据。\n',
            url: 'http://101.37.134.104:1200/Accounts/Login',
            img: 9
          },
          {
            id: 3,
            title: '嘉峪关市公共资源交易电子服务系统',
            desc: '实现招投标过程电子化、规范化，适用于任何交易方式，包括公开招标、邀请招标、竞争性谈判、竞争性磋商、询价、单一来源等等',
            url: 'http://120.27.242.171:8091/Accounts/Login',
            img: 15
          },
          {
            id: 4,
            title: '网上开评标系统',
            desc: '在线开标系统实现全程开标电子化，开标各环节均系统留痕，保证公平公正，减少人为因素干预；\n在线评标系统根据招标文件设定的评标方法、评审因素和标准设置评审表格和评审项目；具备按招标文件约定的评标方法，对投标文件进行解析、对比，辅助评分或计算评标价的功能；系统可自动生成评标报告，评委会成员可进行电子签名。\n',
            url: 'https://wskpb.ggzyjy.gansu.gov.cn/OpenTender/login',
            img: 7
          },
          {
            id: 5,
            title: '嘉峪关市土地矿权交易系统',
            desc: '土地和矿业权交易系统可以实现标的物的网上挂牌和拍卖，具备满足标的物交易所需的保证金管理功能和延时竞价功能，可根据实际需求设置竞价策略。\n',
            url: 'http://101.37.193.97:3030/a/login',
            img: 5
          },
          {
            id: 6,
            title: '嘉峪关市国有产权交易系统',
            desc: '国有产权交易系统可以实现标的物的网上挂牌和拍卖，具备满足标的物交易所需的保证金管理功能和延时竞价功能，可根据实际需求设置竞价策略。\n',
            url: 'http://101.37.193.97:3050/a/login',
            img: 14
          },
          {
            id: 7,
            title: '甘肃省阳光招标采购平台（嘉峪关市）',
            desc: '',
            url: 'http://www.jygzyjy.gov.cn/f/sunIndex'
          },
          {
            id: 8,
            title: '嘉峪关市智慧监管系统',
            desc: '',
            url: 'http://47.97.94.251:9002/#/login'
          },
          {
            id: 9,
            title: '嘉峪关大数据平台',
            desc: '大数据平台全面实现了数据的采集、清理、存储、分析、展现和应用。采用了可视化数据展现方式，直观的向各类用户展现统计分析结果；\n系统提供开放的数据接口，开放的对接公共资源交易或企业相关的各类系统，为政府/企业决策提供支撑、为交易监管提供线索、为交易主体提供精准的个性化服务。\n',
            url: 'https://dsj.ggzyjy.gansu.gov.cn/#/login',
            img: 11
          },
          {
            id: 10,
            title: '嘉峪关市电子档案系统',
            desc: '对招投标过程所有资料，并上链存储，保证无法进行修改，后期可以在问题追溯、审计等场景中查看项目资料。',
            url: 'http://47.97.94.251:27001/archives/#/login',
            img: 6
          },
          {
            id: 11,
            title: '见证系统',
            desc: '独立于电子服务系统和电子交易系统运行，可全量记录交易过程中的所有交易数据及交易主体行为数据，形成交易见证档案，并采用数字签名和时间戳等技术手段保证数据的合法有效性，真正实现交易全过程的可溯可查，并为大数据平台提供可靠的数据源，为实现智慧监管奠定基础，为远程在线监管交易提供了有效手段。',
            url: 'http://47.97.94.251:9001/#/login',
            img: 3
          },
          {
            id: 12,
            title: '保证金系统',
            desc: '',
            url: 'http://120.27.242.171:8091/Accounts/Login'
          },
          {
            id: 13,
            title: '电子保函',
            desc: '电子保函系统实现电子保函在线投保、审核、支付、出单等全流程线上办理，可为公共资源交易中心等平台提供电子保函',
            url: 'http://120.27.242.171:8091/Accounts/Login',
            img: 3
          },
          {
            id: 14,
            title: '简易网',
            desc: '',
            url: 'http://www.jian-yi.com/login?'
          },
          {
            id: 15,
            title: '专家库系统',
            desc: '专家抽取过程人工参与，无法保证专家信息的保密性；\n对于专家的评标过程是否存在倾向性，无法进行评估。',
            url: '',
            img: 10
          }
        ]
      },
      {
        id: 5,
        title: '天水市公共资源交易中心',
        imgUrl: 'https://traceable-platform.oss-cn-hangzhou.aliyuncs.com/test/temp/20230426/430462c727474edfba95d8cd789cb17d.png',
        ability: [
          {
            id: 1,
            title: '政府采购协议供货项目阳光交易系统（天水市）',
            desc: '',
            url: 'http://115.28.253.155/a/login'
          },
          {
            id: 2,
            title: '限额以下工程项目阳光交易系统（天水市）',
            desc: '',
            url: 'http://118.190.126.140/a/login'
          },
          {
            id: 3,
            title: '政府采购限额以下项目阳光交易系统（天水市）',
            desc: '',
            url: 'http://115.28.253.155:8083/a/login'
          },
          {
            id: 4,
            title: '网上开评标系统',
            desc: '在线开标系统实现全程开标电子化，开标各环节均系统留痕，保证公平公正，减少人为因素干预；\n在线评标系统根据招标文件设定的评标方法、评审因素和标准设置评审表格和评审项目；具备按招标文件约定的评标方法，对投标文件进行解析、对比，辅助评分或计算评标价的功能；系统可自动生成评标报告，评委会成员可进行电子签名。\n',
            url: 'https://wskpb.ggzyjy.gansu.gov.cn/OpenTender/login',
            img: 7
          },
          {
            id: 5,
            title: '专家库系统',
            desc: '专家抽取过程人工参与，无法保证专家信息的保密性；\n对于专家的评标过程是否存在倾向性，无法进行评估。',
            url: 'http://60.165.152.92:9091/a/login',
            img: 10
          },
          {
            id: 6,
            title: '数字证书（CA）互认共享平台',
            desc: '交易主体自主进行注册登记，进行统一身份认证，认证后的身份信息作用于整个电子交易平台中，交易主体管理系统统一定义交易主体的身份类别，在电子交易平台各个子系统或接入了交易主体互认共享平台的其他交易平台中使用统一的身份信息及系统角色登录和操作系统。\n在交易主体管理系统以交易主体的维度汇总电子交易平台，自动评估交易主体信用信息，并生成各交易主体的信用报告，为评标专家及甲方提供辅助依据。',
            url: 'http://ggzyjy.tianshui.gov.cn/f/shuzikey',
            img: 1
          },
          {
            id: 7,
            title: '天水市智慧监管平台',
            url: 'http://60.165.152.92:8090/a/login'
          },
          {
            id: 8,
            title: '电子档案系统',
            desc: '对招投标过程所有资料，并上链存储，保证无法进行修改，后期可以在问题追溯、审计等场景中查看项目资料。',
            url: 'http://125.74.93.153:27001/archives/#/login',
            img: 6
          },
          {
            id: 9,
            title: '天水市公共资源交易中心',
            desc: '交易信息全面公开的门户，不仅作为采购方与投标方联系的“纽带”，也是交易平台显示“公平、公正、公开”最直接的方式及载体。\n交易平台运营方可向公众发布关于交易平台各项通知等内容，采购方可向不特定群体公开发布项目信息，投标人也可在网站中咨询相关问题或表达相关质疑及投诉。\n',
            url: 'http://ggzyjy.tianshui.gov.cn/f/shuzikey',
            img: 15
          }
        ]
      }
    ]
  },
  {
    id: 2,
    title: '企业招标采购领域',
    sys: [
      {
        id: 1,
        title: '酒钢集团',
        imgUrl: 'https://traceable-platform.oss-cn-hangzhou.aliyuncs.com/test/temp/20230428/3a1febd7bbf04a3b872ace48188b1b4d.png',
        imgStyle: {
          height: '100%',
          width: 'auto'
        },
        ability: [
          {
            id: 1,
            title: '网站',
            desc: '交易信息全面公开的门户，不仅作为采购方与投标方联系的“纽带”，也是交易平台显示“公平、公正、公开”最直接的方式及载体。\n交易平台运营方可向公众发布关于交易平台各项通知等内容，采购方可向不特定群体公开发布项目信息，投标人也可在网站中咨询相关问题或表达相关质疑及投诉。\n',
            img: 13,
            url: 'https://eps.jiugangbid.com/f?inward=2'
          },
          {
            id: 2,
            title: '服务系统',
            desc: '实现招投标过程电子化、规范化，适用于任何交易方式，包括公开招标、邀请招标、竞争性谈判、竞争性磋商、询价、单一来源等等。',
            img: 15
          },
          {
            id: 3,
            title: '开评标系统',
            desc: '在线开标系统实现全程开标电子化，开标各环节均系统留痕，保证公平公正，减少人为因素干预；\n在线评标系统根据招标文件设定的评标方法、评审因素和标准设置评审表格和评审项目；具备按招标文件约定的评标方法，对投标文件进行解析、对比，辅助评分或计算评标价的功能；系统可自动生成评标报告，评委会成员可进行电子签名。\n',
            img: 7
          },
          {
            id: 4,
            title: '非标系统',
            desc: '',
            url: ''
          },
          {
            id: 5,
            title: '阳光采购系统',
            desc: '',
            url: ''
          },
          {
            id: 6,
            title: '竞买系统',
            desc: '',
            url: ''
          },
          {
            id: 7,
            title: '专家库',
            desc: '专家抽取过程人工参与，无法保证专家信息的保密性；\n对于专家的评标过程是否存在倾向性，无法进行评估。',
            img: 10
          },
          {
            id: 8,
            title: '网站',
            desc: '交易信息全面公开的门户，不仅作为采购方与投标方联系的“纽带”，也是交易平台显示“公平、公正、公开”最直接的方式及载体。\n交易平台运营方可向公众发布关于交易平台各项通知等内容，采购方可向不特定群体公开发布项目信息，投标人也可在网站中咨询相关问题或表达相关质疑及投诉。\n',
            img: 13
          },
          {
            id: 9,
            title: '见证系统',
            desc: '独立于电子服务系统和电子交易系统运行，可全量记录交易过程中的所有交易数据及交易主体行为数据，形成交易见证档案，并采用数字签名和时间戳等技术手段保证数据的合法有效性，真正实现交易全过程的可溯可查，并为大数据平台提供可靠的数据源，为实现智慧监管奠定基础，为远程在线监管交易提供了有效手段。',
            img: 3
          },
          {
            id: 10,
            title: '档案系统',
            desc: '对招投标过程所有资料，并上链存储，保证无法进行修改，后期可以在问题追溯、审计等场景中查看项目资料。',
            img: 6
          },
          {
            id: 11,
            title: '保证金系统',
            desc: '',
            url: ''
          }
        ]
      },
      {
        id: 2,
        title: '甘肃公航旅集团',
        imgUrl: 'https://traceable-platform.oss-cn-hangzhou.aliyuncs.com/test/temp/20230428/3c2fa62475544abebb7c6347b915ad27.jpg',
        ability: [
          {
            id: 1,
            title: '',
            desc: '',
            url: ''
          }
        ]
      },
      {
        id: 3,
        title: '丝绸之路信息港',
        imgUrl: 'https://traceable-platform.oss-cn-hangzhou.aliyuncs.com/test/temp/20230428/f35ffdd0656b454584f0483569907443.png',
        ability: [
          {
            id: 1,
            title: '',
            desc: '',
            url: ''
          }
        ]
      },
      {
        id: 4,
        title: '陕西省政府采购协会',
        imgUrl: 'https://traceable-platform.oss-cn-hangzhou.aliyuncs.com/test/temp/20230428/a2440eb876cc4007873ed860b2916bc2.jpg',
        ability: [
          {
            id: 1,
            title: '',
            desc: '',
            url: ''
          }
        ]
      }
    ]
  },
  {
    id: 3,
    title: '数字农业领域',
    sys: [
      {
        id: 1,
        title: '康乐县',
        imgUrl: '',
        ability: [
          {
            id: 1,
            title: '云上乡村农业资源交易平台',
            desc: '《云上乡村农业资源交易平台》平台面向全国，服务于农业产业链前端的各个产业主体，有最初始种植、养殖阶段的农民、家庭农场、农业合作社、农业企业；有农产品流通环节的经纪人、农产品需求企业、以农产品为货源的线下专业批发市场、交易市场；还有为乡村农业产业服务的各类型供货企业，如种子地膜、肥料农机、饲料畜药等企业，还向服务于乡村的物流、保险、金融等机构开放，在线为各类农业主体开展线上服务。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 5
          },
          {
            id: 2,
            title: '农业大数据监管',
            desc: '利用大数据实时分析支撑，线上线下结合，整合分散的乡村农业种养殖生产能力，统筹运营地方大宗农畜产品，同时统筹区域内农业生产需求，引入可靠、优质的厂商向农户提供线上定购、线下配送农业物资。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 11
          },
          {
            id: 3,
            title: '农畜产品交易',
            desc: '以增加农民收益为出发点，市场化运营，覆盖农畜产品、生产资料等各类农畜产品在线交易，链接服务农业生产的产、供、销全产业链，利用线上交易减少在供应链各环节的损耗。同时以大数据分析结果为支撑，将分散的农畜产品统筹整合运营，提升区域农产品品牌附加值和市场溢价能力，让农民直接受益，增加收入。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 14
          },
          {
            id: 4,
            title: '农业产权交易服务',
            desc: '面向辖区农户、企业等农业主体，提供农村产权交易服务，实现农村土地、圈舍租赁、农机租赁、林业资源、水利资源等在线流转、交易服务，并结合政务服务系统提供涉农政务端口在农户端的集成服务。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 10
          },
          {
            id: 5,
            title: '农畜产品溯源',
            desc: '与平台农业产业管理融合，贯穿种植源头、养殖产品出生、饲喂、防疫、交易、运输、屠宰等各个环节，应用物联网、区块链等技术记录各个关键环节数据，达到农产品生产、流通、加工、销售、消费全过程溯源管理。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 5
          },
          {
            id: 6,
            title: '乡村政务服务',
            desc: '面向为民政务服务提供手机、PC等多端口的涉农窗口服务能力，可在线管理乡村党建、政务业务，在线提供为民服务，如奖补政策申请、户情管理等，辅助惠农政策落地。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 13
          },
          {
            id: 7,
            title: '农业碳汇交易服务',
            desc: '农业产业为绿色产业，“农业碳汇”是通过农业种植、植树造林、植被恢复等措施，吸收大气中的二氧化碳、减少温室气体在大气中浓度。为响应中央“碳达峰、碳中和”方针政策，信息港推动农业碳汇交易平台，与相关部门政策、业务衔接，通过提供开发、测算、交易、登记农业碳汇等一站式服务，促进农业生产转化为碳交易产品，推动农业“碳票”变“钞票”，为乡村振兴赋能，为农民增收开辟新途径。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 8
          },
          {
            id: 8,
            title: '农牧产业招商服务',
            desc: '县区、镇、村各级政务部门或集体经济、龙头企业等，依据当地资源禀赋、产业优势，结合区域农业产业链短板弱项，为引导社会资本参与乡村振兴，搭建农业领域内包括招商政策、招商信息发布、客商在线对接等，发挥窗口桥梁作用的农牧产业招商服务平台。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 1
          },
          {
            id: 9,
            title: '农业社会化监管服务',
            desc: '农业产业为绿色产业，“农业碳汇”是通过农业种植、植树造林、植被恢复等措施，吸收大气中的二氧化碳、减少温室气体在大气中浓度。为响应中央“碳达峰、碳中和”方针政策，信息港推动农业碳汇交易平台，与相关部门政策、业务衔接，通过提供开发、测算、交易、登记农业碳汇等一站式服务，促进农业生产转化为碳交易产品，推动农业“碳票”变“钞票”，为乡村振兴赋能，为农民增收开辟新途径。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 9
          }
        ]
      },
      {
        id: 2,
        title: '东乡县',
        imgUrl: '',
        ability: [
          {
            id: 1,
            title: '云上乡村农业资源交易平台',
            desc: '《云上乡村农业资源交易平台》平台面向全国，服务于农业产业链前端的各个产业主体，有最初始种植、养殖阶段的农民、家庭农场、农业合作社、农业企业；有农产品流通环节的经纪人、农产品需求企业、以农产品为货源的线下专业批发市场、交易市场；还有为乡村农业产业服务的各类型供货企业，如种子地膜、肥料农机、饲料畜药等企业，还向服务于乡村的物流、保险、金融等机构开放，在线为各类农业主体开展线上服务。',
            url: 'http://ysxc.cc/#/index',
            img: 5
          },
          {
            id: 2,
            title: '农业大数据监管',
            desc: '利用大数据实时分析支撑，线上线下结合，整合分散的乡村农业种养殖生产能力，统筹运营地方大宗农畜产品，同时统筹区域内农业生产需求，引入可靠、优质的厂商向农户提供线上定购、线下配送农业物资。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 11
          },
          {
            id: 3,
            title: '农畜产品交易',
            desc: '以增加农民收益为出发点，市场化运营，覆盖农畜产品、生产资料等各类农畜产品在线交易，链接服务农业生产的产、供、销全产业链，利用线上交易减少在供应链各环节的损耗。同时以大数据分析结果为支撑，将分散的农畜产品统筹整合运营，提升区域农产品品牌附加值和市场溢价能力，让农民直接受益，增加收入。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 14
          },
          {
            id: 4,
            title: '农业产权交易服务',
            desc: '面向辖区农户、企业等农业主体，提供农村产权交易服务，实现农村土地、圈舍租赁、农机租赁、林业资源、水利资源等在线流转、交易服务，并结合政务服务系统提供涉农政务端口在农户端的集成服务。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 10
          },
          {
            id: 5,
            title: '农畜产品溯源',
            desc: '与平台农业产业管理融合，贯穿种植源头、养殖产品出生、饲喂、防疫、交易、运输、屠宰等各个环节，应用物联网、区块链等技术记录各个关键环节数据，达到农产品生产、流通、加工、销售、消费全过程溯源管理。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 5
          },
          {
            id: 6,
            title: '乡村政务服务',
            desc: '面向为民政务服务提供手机、PC等多端口的涉农窗口服务能力，可在线管理乡村党建、政务业务，在线提供为民服务，如奖补政策申请、户情管理等，辅助惠农政策落地。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 13
          },
          {
            id: 7,
            title: '农业碳汇交易服务',
            desc: '农业产业为绿色产业，“农业碳汇”是通过农业种植、植树造林、植被恢复等措施，吸收大气中的二氧化碳、减少温室气体在大气中浓度。为响应中央“碳达峰、碳中和”方针政策，信息港推动农业碳汇交易平台，与相关部门政策、业务衔接，通过提供开发、测算、交易、登记农业碳汇等一站式服务，促进农业生产转化为碳交易产品，推动农业“碳票”变“钞票”，为乡村振兴赋能，为农民增收开辟新途径。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 8
          },
          {
            id: 8,
            title: '农牧产业招商服务',
            desc: '县区、镇、村各级政务部门或集体经济、龙头企业等，依据当地资源禀赋、产业优势，结合区域农业产业链短板弱项，为引导社会资本参与乡村振兴，搭建农业领域内包括招商政策、招商信息发布、客商在线对接等，发挥窗口桥梁作用的农牧产业招商服务平台。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 1
          },
          {
            id: 9,
            title: '农业社会化监管服务',
            desc: '农业产业为绿色产业，“农业碳汇”是通过农业种植、植树造林、植被恢复等措施，吸收大气中的二氧化碳、减少温室气体在大气中浓度。为响应中央“碳达峰、碳中和”方针政策，信息港推动农业碳汇交易平台，与相关部门政策、业务衔接，通过提供开发、测算、交易、登记农业碳汇等一站式服务，促进农业生产转化为碳交易产品，推动农业“碳票”变“钞票”，为乡村振兴赋能，为农民增收开辟新途径。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 9
          }
        ]
      },
      {
        id: 3,
        title: '古浪县',
        imgUrl: '',
        ability: [
          {
            id: 1,
            title: '云上乡村农业资源交易平台',
            desc: '《云上乡村农业资源交易平台》平台面向全国，服务于农业产业链前端的各个产业主体，有最初始种植、养殖阶段的农民、家庭农场、农业合作社、农业企业；有农产品流通环节的经纪人、农产品需求企业、以农产品为货源的线下专业批发市场、交易市场；还有为乡村农业产业服务的各类型供货企业，如种子地膜、肥料农机、饲料畜药等企业，还向服务于乡村的物流、保险、金融等机构开放，在线为各类农业主体开展线上服务。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 5
          },
          {
            id: 2,
            title: '农业大数据监管',
            desc: '利用大数据实时分析支撑，线上线下结合，整合分散的乡村农业种养殖生产能力，统筹运营地方大宗农畜产品，同时统筹区域内农业生产需求，引入可靠、优质的厂商向农户提供线上定购、线下配送农业物资。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 11
          },
          {
            id: 3,
            title: '农畜产品交易',
            desc: '以增加农民收益为出发点，市场化运营，覆盖农畜产品、生产资料等各类农畜产品在线交易，链接服务农业生产的产、供、销全产业链，利用线上交易减少在供应链各环节的损耗。同时以大数据分析结果为支撑，将分散的农畜产品统筹整合运营，提升区域农产品品牌附加值和市场溢价能力，让农民直接受益，增加收入。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 14
          },
          {
            id: 4,
            title: '农业产权交易服务',
            desc: '面向辖区农户、企业等农业主体，提供农村产权交易服务，实现农村土地、圈舍租赁、农机租赁、林业资源、水利资源等在线流转、交易服务，并结合政务服务系统提供涉农政务端口在农户端的集成服务。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 10
          },
          {
            id: 5,
            title: '农畜产品溯源',
            desc: '与平台农业产业管理融合，贯穿种植源头、养殖产品出生、饲喂、防疫、交易、运输、屠宰等各个环节，应用物联网、区块链等技术记录各个关键环节数据，达到农产品生产、流通、加工、销售、消费全过程溯源管理。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 5
          },
          {
            id: 6,
            title: '乡村政务服务',
            desc: '面向为民政务服务提供手机、PC等多端口的涉农窗口服务能力，可在线管理乡村党建、政务业务，在线提供为民服务，如奖补政策申请、户情管理等，辅助惠农政策落地。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 13
          },
          {
            id: 7,
            title: '农业碳汇交易服务',
            desc: '农业产业为绿色产业，“农业碳汇”是通过农业种植、植树造林、植被恢复等措施，吸收大气中的二氧化碳、减少温室气体在大气中浓度。为响应中央“碳达峰、碳中和”方针政策，信息港推动农业碳汇交易平台，与相关部门政策、业务衔接，通过提供开发、测算、交易、登记农业碳汇等一站式服务，促进农业生产转化为碳交易产品，推动农业“碳票”变“钞票”，为乡村振兴赋能，为农民增收开辟新途径。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 8
          },
          {
            id: 8,
            title: '农牧产业招商服务',
            desc: '县区、镇、村各级政务部门或集体经济、龙头企业等，依据当地资源禀赋、产业优势，结合区域农业产业链短板弱项，为引导社会资本参与乡村振兴，搭建农业领域内包括招商政策、招商信息发布、客商在线对接等，发挥窗口桥梁作用的农牧产业招商服务平台。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 1
          },
          {
            id: 9,
            title: '农业社会化监管服务',
            desc: '农业产业为绿色产业，“农业碳汇”是通过农业种植、植树造林、植被恢复等措施，吸收大气中的二氧化碳、减少温室气体在大气中浓度。为响应中央“碳达峰、碳中和”方针政策，信息港推动农业碳汇交易平台，与相关部门政策、业务衔接，通过提供开发、测算、交易、登记农业碳汇等一站式服务，促进农业生产转化为碳交易产品，推动农业“碳票”变“钞票”，为乡村振兴赋能，为农民增收开辟新途径。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 9
          }
        ]
      },
      {
        id: 4,
        title: '甘州区',
        imgUrl: '',
        ability: [
          {
            id: 1,
            title: '云上乡村农业资源交易平台',
            desc: '《云上乡村农业资源交易平台》平台面向全国，服务于农业产业链前端的各个产业主体，有最初始种植、养殖阶段的农民、家庭农场、农业合作社、农业企业；有农产品流通环节的经纪人、农产品需求企业、以农产品为货源的线下专业批发市场、交易市场；还有为乡村农业产业服务的各类型供货企业，如种子地膜、肥料农机、饲料畜药等企业，还向服务于乡村的物流、保险、金融等机构开放，在线为各类农业主体开展线上服务。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 5
          },
          {
            id: 2,
            title: '农业大数据监管',
            desc: '利用大数据实时分析支撑，线上线下结合，整合分散的乡村农业种养殖生产能力，统筹运营地方大宗农畜产品，同时统筹区域内农业生产需求，引入可靠、优质的厂商向农户提供线上定购、线下配送农业物资。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 11
          },
          {
            id: 3,
            title: '农畜产品交易',
            desc: '以增加农民收益为出发点，市场化运营，覆盖农畜产品、生产资料等各类农畜产品在线交易，链接服务农业生产的产、供、销全产业链，利用线上交易减少在供应链各环节的损耗。同时以大数据分析结果为支撑，将分散的农畜产品统筹整合运营，提升区域农产品品牌附加值和市场溢价能力，让农民直接受益，增加收入。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 14
          },
          {
            id: 4,
            title: '农业产权交易服务',
            desc: '面向辖区农户、企业等农业主体，提供农村产权交易服务，实现农村土地、圈舍租赁、农机租赁、林业资源、水利资源等在线流转、交易服务，并结合政务服务系统提供涉农政务端口在农户端的集成服务。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 10
          },
          {
            id: 5,
            title: '农畜产品溯源',
            desc: '与平台农业产业管理融合，贯穿种植源头、养殖产品出生、饲喂、防疫、交易、运输、屠宰等各个环节，应用物联网、区块链等技术记录各个关键环节数据，达到农产品生产、流通、加工、销售、消费全过程溯源管理。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 5
          },
          {
            id: 6,
            title: '乡村政务服务',
            desc: '面向为民政务服务提供手机、PC等多端口的涉农窗口服务能力，可在线管理乡村党建、政务业务，在线提供为民服务，如奖补政策申请、户情管理等，辅助惠农政策落地。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 13
          },
          {
            id: 7,
            title: '农业碳汇交易服务',
            desc: '农业产业为绿色产业，“农业碳汇”是通过农业种植、植树造林、植被恢复等措施，吸收大气中的二氧化碳、减少温室气体在大气中浓度。为响应中央“碳达峰、碳中和”方针政策，信息港推动农业碳汇交易平台，与相关部门政策、业务衔接，通过提供开发、测算、交易、登记农业碳汇等一站式服务，促进农业生产转化为碳交易产品，推动农业“碳票”变“钞票”，为乡村振兴赋能，为农民增收开辟新途径。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 8
          },
          {
            id: 8,
            title: '农牧产业招商服务',
            desc: '县区、镇、村各级政务部门或集体经济、龙头企业等，依据当地资源禀赋、产业优势，结合区域农业产业链短板弱项，为引导社会资本参与乡村振兴，搭建农业领域内包括招商政策、招商信息发布、客商在线对接等，发挥窗口桥梁作用的农牧产业招商服务平台。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 1
          },
          {
            id: 9,
            title: '农业社会化监管服务',
            desc: '农业产业为绿色产业，“农业碳汇”是通过农业种植、植树造林、植被恢复等措施，吸收大气中的二氧化碳、减少温室气体在大气中浓度。为响应中央“碳达峰、碳中和”方针政策，信息港推动农业碳汇交易平台，与相关部门政策、业务衔接，通过提供开发、测算、交易、登记农业碳汇等一站式服务，促进农业生产转化为碳交易产品，推动农业“碳票”变“钞票”，为乡村振兴赋能，为农民增收开辟新途径。',
            url: 'https://www.ysxc.com.cn/#/webSite/index',
            img: 9
          }
        ]
      }
    ]
  }
]
