<template>
  <div class="suspension">
    <div
      class="suspension-item"
      v-for="item,index in list"
      :key="index"
      @click="item.fun && item.fun()"
    >
      <img :src="item.img">
      <p v-text="item.label" />
      <components
        :is="item.componentsName"
        :content-list="item.content"
        v-if="item.type === 'components'"
      />
    </div>
  </div>
</template>

<script>
import vPhone from './hoverPhone.vue'
import vQRCode from './hoverQRCode.vue'
export default {
  components: {
    vPhone,
    vQRCode
  },
  data () {
    return {
      list: [
        {
          img: '/static/img/home/suspension/a-1.png',
          label: '电话咨询',
          type: 'components',
          componentsName: 'vPhone',
          content: [
            {
              title: '商务电话',
              value: ['17794273818']
            },
            {
              title: '客服电话',
              value: ['0931-4267890']
            }
          ]
        },
        {
          img: '/static/img/home/suspension/a-2.png',
          label: '在线咨询'
        },
        {
          img: '/static/img/home/suspension/a-3.png',
          label: '微信联系',
          type: 'components',
          componentsName: 'vQRCode',
          content: [
            '/static/img/home/suspension/QRCode-wx.png'
          ]
        },
        {
          img: '/static/img/home/suspension/a-4.png',
          label: '关注微博',
          type: 'components',
          componentsName: 'vQRCode',
          content: [
            '/static/img/home/suspension/QRCode-wb.png'
          ]
        },
        {
          img: '/static/img/home/suspension/a-5.png',
          label: '回到顶部',
          type: 'fun',
          fun: () => {
            window.scrollTo(0, 0)
          }
        }
      ]
    }
  }
}
</script>

<style lang="less" scoped>
.suspension{
    position: fixed;
    top: 50%;
    left: 52%;
    transform: translate(calc(@minWith / 2), -50%);
    z-index: 999;
    box-shadow: 0px 1px 14px 1px rgba(0,0,0,0.06);;
    border-radius: 4px;
    background: #fff;
    &-item{
        position: relative;
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        cursor: pointer;
        &:not(:last-child)::after{
            content: "";
            position: absolute;
            left: 50%;
            bottom: 0px;
            transform: translateX(-50%);
            width: 70%;
            height: 1px;
            background: #333;
            opacity: 0.1;
        }
        &:hover{
            background-color: #dfe4f5;
        }
        img{
            height: 38px;
            width: 38px;
            margin-bottom: 6px;
        }
        p{
            color: #666;
            font-size: 12px;
        }
    }
}
// 媒体查询窗口高度小于768px
@media screen and (max-height: 600px) {
  .suspension{
    top: 120px;
    transform: translate(calc(@minWith / 2), 0);
  }
}

</style>
