<template>
  <div class="echartsMap">
    <div
      id="threeDmap"
      ref="threeDmap"
    />
  </div>
</template>

<script>
import * as echarts from 'echarts'
// import 'echarts-gl' // 3D地图插件
import mapJson from './620000.json'
export default {
  data () {
    return {
      myChart: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.getMap()
    })
  },
  methods: {
    getMap () {
      const dom = this.$refs.threeDmap
      this.myChart = echarts.init(dom)
      const mainColor = '#5380fa'
      const bgColor = '#5380fa33'
      const option = {
        geo: {
          show: true,
          map: 'gansu', // 使用 registerMap 注册的地图名称。
          label: {
            show: false
          },
          // 设置外部地图的样式
          itemStyle: {
            areaColor: bgColor,
            color: bgColor,
            borderWidth: 0
          },
          emphasis: {
            disabled: true,
            label: {
              show: false
            }
          },
          silent: true
        },
        series: [
          {
            type: 'map', // 地图类型
            name: '甘肃', // 系列名称，用于tooltip的显示，legend 的图例筛选，在 setOption 更新数据和配置项时用于指定对应的系列。
            map: 'gansu', // registerMap 注册地图的名称 需对应
            // 图形上的文本标签
            label: {
              show: true,
              color: mainColor,
              fontWeight: 600,
              fontFamily: 'PingFangSC-Semibold',
              fontSize: 16,
              align: 'center',
              // 文本阴影
              textShadowColor: '#ffffff33',
              textBorderColor: '#ffffff33',
              textShadowBlur: 1
            },
            // 地图区域的多边形 图形样式
            itemStyle: {
              borderColor: mainColor,
              borderWidth: 1,
              borderType: 'solid',
              areaColor: '#fff'
            },
            // 高亮样式。
            emphasis: {
              itemStyle: {
                areaColor: mainColor,
                color: mainColor
              },
              label: {
                show: true,
                color: '#fff',
                // 文本阴影
                textShadowColor: mainColor,
                textBorderColor: mainColor,
                textShadowBlur: 2
              }
            },
            top: '7%'
          }
        ]
      }
      const geoJson = mapJson

      this.myChart.showLoading()
      echarts.registerMap('gansu', geoJson)
      this.myChart.on('click', (params) => {
        // 获取数据中的地区编号
        this.$emit('click', params.name)
        // 取消之前高亮的图形
        this.clearHighlight()
        // 点击地区高亮
        this.myChart.dispatchAction({
          type: 'highlight',
          seriesName: '甘肃',
          name: params.name
        })
      })
      this.myChart.setOption(option)
      this.myChart.hideLoading()
    },
    clearHighlight () {
      this.myChart.dispatchAction({
        type: 'downplay',
        seriesIndex: 0
      })
    }
  }
}
</script>

<style lang="less" scoped>
.echartsMap {
  width: 100%;
  height: 100%;
  position: relative;
}
#threeDmap {
  position: absolute;
  left: 50%;
  top: -90px;
  transform: translateX(-50%);
  width: 100%;
  height: 800px;
}
</style>
