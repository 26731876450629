<template>
  <div class="news-nav">
    <div class="news-nav-list">
      <template v-for="(item, index) in list">
        <div
          class="news-nav-list-item"
          :class="{ active: index === activeIndex }"
          :key="index"
          @click="handleClick(item, index)"
        >
          {{ item.title }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => []
    },
    activeIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {}
  },
  methods: {
    handleClick (item, index) {
      // TODO: 是否获取完成数据在刷新下标
      this.$emit('change', item.id)
      this.$emit('update:activeIndex', index)
    }
  }
}
</script>

<style lang="less" scoped>
.news-nav {
  padding-bottom: 12px;
  margin-bottom: 34px;
  border-bottom: 1px solid #70707027;
  &-list {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    &-item {
      position: relative;
      font-size: 14px;
      color: #000000;
      cursor: pointer;
      transition: cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
      //  选中状态
      &:after {
        content: "";
        position: absolute;
        bottom: -13px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 2px;
        background: @mainColor;
        opacity: 0;
        transition: @gTransition;
      }
      &.active,
      &:hover {
        color: #4978fa;
        &:after {
          opacity: 1;
          width: calc(100% + 10px);
        }
      }
    }
  }
}
</style>
