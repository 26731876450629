<template>
  <div class="product-main">
    <div class="product-main-l">
      <img :src="data.img">
    </div>
    <div class="product-main-r">
      <div class="title">
        {{ data.title }}
      </div>
      <div class="content">
        {{ data.content }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        id: '',
        img: '',
        title: '',
        content: '',
        url: ''
      })
    }
  },
  computed: {
  },
  methods: {
    toDetail (id) {
      console.log('id', id)
      window.open(this.data.url)
    }
  }
}
</script>

<style lang="less" scoped>
.product-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 300px;
  margin-bottom: 66px;
  .product-main-l {
    width: 520px;
    height: 100%;
    position: relative;
    background: url('/static/img/home/constitute/a-1.png') no-repeat;
    background-size: 100% 100%;
    img {
      width: 390px;
      height: 240px;
      position: absolute;
      top: 21px;
      left: 66px;
    }
  }
  .product-main-r {
    width: calc(100% - 520px);
    .title {
      font-size: 20px;
      color: #000000;
      font-weight: bold;
      line-height: 27px;
      padding-left: 10px;
      border-left: 4px solid #3060e4;
      margin-bottom: 40px;
    }
    .content {
      height: 100px;
      font-size: 14px;
      color: #6d7281;
      padding-left: 20px;
      text-indent: 2em;
    }
  }
}
</style>
