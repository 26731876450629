import { render, staticRenderFns } from "./newsNav.vue?vue&type=template&id=564c0963&scoped=true&"
import script from "./newsNav.vue?vue&type=script&lang=js&"
export * from "./newsNav.vue?vue&type=script&lang=js&"
import style0 from "./newsNav.vue?vue&type=style&index=0&id=564c0963&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "564c0963",
  null
  
)

export default component.exports